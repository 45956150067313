import React, { useContext, useState, useMemo, Fragment } from "react";
import { Pie, Histogram, Line } from "@kido/plots";
import { Loading } from "@kido/ui";
import { useTranslation } from "react-i18next";
import daggy from "daggy";
import { Statistic, Icon } from "antd";
import moment from "moment";
import useAsyncEffect from "../../hooks/useAsyncEffect";
import { Separator } from "./Trip.styles";
import {
  getTripsPerDestination,
  getTripsPerOrigin,
  getTripsPerDay,
  getTripsPerHour,
  getTrips,
  getVisitsByResidence
} from "../../data/request";
import { Context } from "../../store";
import Empty from "../Empty";
import theme from "../../theme";

const UI = daggy.taggedSum("UI", {
  Success: [[]],
  Failure: [],
  Loading: [],
  Empty: [],
  On: []
});

const sortNumber = (a, b) => b.value - a.value;

const Trip = (props) => {
  const [state, dispatch]: any = useContext(Context);
  const [UIState, setUI] = useState(UI.On);
  const [currentLabel, setCurrentLabel] = useState("");
  const { t, i18n } = useTranslation();

  useAsyncEffect(async () => {
    setUI(UI.Loading);

    console.log(state.app);

    const total = await getTrips(state);
    const totalTrips = await getTripsPerDay(state);
    const trips =
      state.origin !== "all" && state.destination === "all"
        ? await getTripsPerDestination(state)
        : await getTripsPerOrigin(state);
    const tripsByHour = await getTripsPerHour(state);
    const visits =
      state.app === "spain" ? await getVisitsByResidence(state) : null;

    setUI(
      UI.Success({
        total: total.data,
        totalTrips: totalTrips.data,
        trips: trips.data,
        tripsByHour: tripsByHour.data,
        visits: visits ? visits.data[0] : null
      })
    );
  }, [state]);

  const onClickPie = (d) => {
    setCurrentLabel(d.label);
  };

  return useMemo(
    () => (
      <div>
        {UIState.cata({
          Success: (data) => {
            return (
              <Fragment>
                <Separator />
                {data.total.data.map((item, key) => (
                  <Statistic
                    key={`${item.label}-${key}`}
                    title={t(item.label)}
                    value={item.value}
                    prefix={<Icon type="user" />}
                  ></Statistic>
                ))}
                <Separator />
                <Separator />
                {data.totalTrips.data[0] ? (
                  <Histogram
                    style={{
                      width: 700,
                      height: 400
                    }}
                    isDesktopOrLaptop
                    locale="en"
                    theme={theme}
                    title="Total trips per day"
                    data={data.totalTrips.data[0].data.map((r, i) => ({
                      value: r,
                      label: data.totalTrips.labels[i]
                    }))}
                  ></Histogram>
                ) : (
                  <Empty />
                )}
                <Separator />
                <Separator />
                <Separator />
                {state.origin !== "all" && state.destination !== "all" ? (
                  <Fragment>
                    {state.app === "spain" &&
                      (data.visits.data[0] ? (
                        <Histogram
                          style={{
                            width: 700,
                            height: 400
                          }}
                          isDesktopOrLaptop
                          locale="en"
                          theme={theme}
                          title="Total visits (Origin = residence)"
                          data={data.visits.data[0].data.map((r, i) => ({
                            value: r,
                            label: data.visits.labels[i]
                          }))}
                        ></Histogram>
                      ) : (
                        <Empty />
                      ))}
                    <Separator />
                    <Separator />
                    <Separator />
                    {Object.keys(data.tripsByHour.data).length ? (
                      <Line
                        style={{
                          width: 699,
                          height: 800
                        }}
                        isDesktopOrLaptop
                        locale="en"
                        theme={theme}
                        config={["hour", "trips"]}
                        subtitle="trips"
                        title="Trips per hour"
                        data={Object.keys(data.tripsByHour.data).map((k) => ({
                          name: k,
                          values: data.tripsByHour.data[k]
                        }))}
                        labels={data.trips.labels.map((d) => {
                          return moment(d).format(theme.dateFormat);
                        })}
                      />
                    ) : (
                      <Empty />
                    )}
                  </Fragment>
                ) : (
                  <Fragment>
                    {data.trips.data.length ? (
                      <Pie
                        style={{
                          width: 700,
                          height: 400
                        }}
                        isDesktopOrLaptop
                        locale="en"
                        theme={theme}
                        data={data.trips.data
                          .map((r, i) => ({
                            value: r,
                            label: state.provinces.filter(
                              (d) => d.id === data.trips.labels[i]
                            )[0].name
                          }))
                          .filter((d) => d.label !== currentLabel)
                          .filter((d) => d.value !== 0)
                          .sort(sortNumber)}
                        id="Trips"
                        onClick={onClickPie}
                        metadata={data.trips.key_value_metadata
                          .filter((m, i) => i !== 0)
                          .map((d) => ({
                            label: t(d.label),
                            value: d.value
                          }))}
                      />
                    ) : (
                      <Empty />
                    )}
                    <Separator />
                    <Separator />
                    <Separator />
                    {state.app === "spain" &&
                      (data.visits.data.length ? (
                        <Pie
                          style={{
                            width: 700,
                            height: 400
                          }}
                          isDesktopOrLaptop
                          locale="en"
                          theme={theme}
                          data={data.visits.data
                            .map((r, i) => ({
                              value: r,
                              label: state.provinces.filter(
                                (d) => d.id === data.visits.labels[i]
                              )[0].name
                            }))
                            .filter((d) => d.label !== currentLabel)
                            .filter((d) => d.value !== 0)
                            .sort(sortNumber)}
                          id="Trips"
                          onClick={onClickPie}
                          metadata={data.visits.key_value_metadata
                            //.filter((m, i) => i !== 0)
                            .map((d) => ({
                              label: t(d.label),
                              value: d.value
                            }))}
                        />
                      ) : (
                        <Empty />
                      ))}
                  </Fragment>
                )}
              </Fragment>
            );
          },
          On: () => <Loading theme={theme} />,
          Failure: (error) => <div>ERROR</div>,
          Loading: () => <Loading theme={theme} />,
          Empty: () => <Empty />
        })}
      </div>
    ),
    [UIState, currentLabel]
  );
};

export default Trip;
