import styled from "styled-components";
import { DatePicker } from "antd";
import theme from "../../theme";

const { RangePicker } = DatePicker;

export const Filters: any = styled.div`
  height: ${({ open }: any) => (open ? "auto" : "20px")};
  overflow: hidden;
  transition: height 0.2s ease-out;
  border-bottom: 1px solid ${theme.borderColor};
  position: relative;
  color: ${theme.fontColor};

  .ant-row {
    margin-bottom: 25px !important;
    display: flex;
    align-items: center;
    min-height: 32px;
  }
`;

export const Dates = styled(RangePicker)`
  .ant-input {
  }

  .ant-calendar-range-picker-input {
    margin-top: 2px !important;
  }
`;

export const Msg = styled.div`
  color: ${theme.fontColor};
`;

export const Reset = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;

export const Flip = styled.button`
  background: none;
  border: none;
  position: absolute;
  top: 30px;
  right: 70px;
  cursor: pointer;
`;
