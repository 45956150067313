import { getFeatureById } from "../utils/map.utils";
import Feature from "ol/Feature";
import { unByKey } from "ol/Observable";
import { extend } from "ol/extent";
var MapViewStateManager = /** @class */ (function () {
    function MapViewStateManager(map, viewStates, rootViewState) {
        if (rootViewState === void 0) { rootViewState = null; }
        this.rootViewState = null;
        this.activeViewState = null;
        this.paddingOptions = [0, 0, 0, 650];
        this.map = map;
        this.viewStatesList = viewStates;
        if (rootViewState) {
            this.rootViewState = rootViewState;
            this.activeViewState = this.rootViewState;
        }
    }
    MapViewStateManager.prototype.getActiveViewState = function () {
        return this.activeViewState;
    };
    MapViewStateManager.prototype.setActiveViewState = function (viewState) {
        this.activeViewState = viewState;
    };
    MapViewStateManager.prototype.setRootViewState = function (root) {
        this.rootViewState = root;
    };
    MapViewStateManager.prototype.getViewByName = function (name) {
        for (var _i = 0, _a = this.viewStatesList; _i < _a.length; _i++) {
            var view = _a[_i];
            if (view.getName() == name) {
                return view;
            }
        }
        return null;
    };
    MapViewStateManager.prototype.listenUserInteractions = function () {
        this.listenUserMouseEvents();
        this.listenChangeResolutionEvent();
    };
    MapViewStateManager.prototype.listenUserMouseEvents = function () {
        var that = this;
        // LEFT CLICK
        this.map.mapObject.on("singleclick", function (evt) {
            that.mouseEventOnMap(evt.pixel, { event: "left_click" });
        });
        // RIGHT CLICK
        this.map.mapObject
            .getViewport()
            .addEventListener("contextmenu", function (evt) {
            evt.preventDefault();
            that.mouseEventOnMap([evt.clientX, evt.clientY], {
                event: "right_click"
            });
        });
        if (this.map.getEnabledHoverBehaviors()) {
            this.map.mapObject.on('pointermove', function (evt) {
                that.hoverEventOnMap(evt);
            });
            this.map.mapObject.getViewport().addEventListener('mouseout', function () {
                that.map.hideTooltipHoverBehavior();
            });
        }
    };
    MapViewStateManager.prototype.mouseEventOnMap = function (eventPixel, eventOptions) {
        var that = this;
        that.map.mapObject.forEachFeatureAtPixel(eventPixel, function (feature, layer) {
            var kidoLayerObjectSelected = (layer.get("kidoLayerObject"));
            var activeView = that.getActiveViewState();
            if (activeView.getTransferLayer() == kidoLayerObjectSelected ||
                kidoLayerObjectSelected.getSelectableBehavior().isSelectable()) {
                that.selectFeatureLayerOnView(activeView, kidoLayerObjectSelected, feature, eventOptions);
                activeView.viewEventHandler.OnFeatureClicked.trigger();
            }
        }, { hitTolerance: that.map.clickHitTolerance });
    };
    MapViewStateManager.prototype.hoverEventOnMap = function (evt) {
        var that = this;
        var layersHoveredCount = 0;
        that.map.mapObject.forEachFeatureAtPixel(evt.pixel, function (feature, layer) {
            var kidoLayerObjectHovered = (layer.get("kidoLayerObject"));
            if (kidoLayerObjectHovered.getHoverBehavior().isHoverable()) {
                kidoLayerObjectHovered.getHoverBehavior().onHoverAction(evt, feature);
            }
            layersHoveredCount += 1;
        }, { hitTolerance: that.map.clickHitTolerance });
        if (layersHoveredCount == 0 && this.map.getTooltipHoverBehaviorOverlay()) {
            this.map.hideTooltipHoverBehavior();
        }
    };
    MapViewStateManager.prototype.listenChangeResolutionEvent = function () {
        var that = this;
        // ZOOM CHANGE EVENTS
        this.changeResolutionListener = this.map.mapObject
            .getView()
            .on("change:resolution", function (evt) {
            var currentZoom = that.map.mapObject.getView().getZoom();
            var currentView = that.getActiveViewState();
            if (!that.map.itsOnShowcaseMode &&
                currentView.getParentViewState() !== null &&
                currentZoom <= currentView.getParentViewState().childZoomTreshold) {
                that.moveToParentView();
            }
        });
    };
    MapViewStateManager.prototype.unlistenChangeResolutionEvent = function () {
        unByKey(this.changeResolutionListener);
    };
    MapViewStateManager.prototype.moveToChildView = function () {
        var _this = this;
        var parentView = this.getActiveViewState();
        var that = this;
        if (parentView.getChildViewState() !== null) {
            parentView.viewEventHandler.OnMoveToChild.trigger();
            this.updateActiveViewState(parentView.getChildViewState());
            console.log("MOVING TO:", this.getActiveViewState().getName());
            this.getActiveViewState().viewEventHandler.OnBoundaryLayerUpdated.on(function () {
                _this.fitToExtent(that
                    .getActiveViewState()
                    .getBoundaryReferenceLayer()
                    .getMapLayerObject()
                    .getSource()
                    .getExtent(), parentView.childZoomTreshold + 3);
            });
            this.getActiveViewState().enterViewFromParent();
        }
    };
    MapViewStateManager.prototype.moveToParentView = function () {
        var currentView = this.getActiveViewState();
        if (currentView.getParentViewState() !== null) {
            // When move to its parent, we reset the view layers to its initial state
            currentView.resetLayersObjectsState();
            this.getActiveViewState().viewEventHandler.OnMoveToParent.trigger();
            this.updateActiveViewState(currentView.getParentViewState());
        }
    };
    // The active view needs to be changed
    // Show layers of the new active state; and hide the old ones
    MapViewStateManager.prototype.updateActiveViewState = function (newActiveState) {
        this.getActiveViewState()
            .getLayersGroup()
            .setVisible(false);
        newActiveState.getLayersGroup().setVisible(true);
        this.setActiveViewState(newActiveState);
        this.activeInformationalLayers = this.getActiveViewState().getInformationalLayers();
        this.getActiveViewState().viewEventHandler.OnEnter.trigger();
    };
    MapViewStateManager.prototype.selectFeatureLayerOnView = function (referenceView, layer, feature, selectOptions) {
        if (!(feature instanceof Feature)) {
            feature = getFeatureById(layer, feature);
        }
        if (layer.getSelectableBehavior().isSelectable()) {
            layer
                .getSelectableBehavior()
                .onSelectAction(referenceView, feature, selectOptions);
        }
        referenceView.setLayerSelected(layer);
        referenceView.setFeatureSelected(feature);
        var activeView = this.getActiveViewState();
        if (referenceView.getChildViewState()) {
            referenceView.getChildViewState().resetLayersObjectsState();
        }
        else {
            // If the reference view doesn't have a child (it's a leaf), zoom to the features selected
            this.fitToViewFeaturesSelectedExtent(referenceView);
        }
        if (referenceView.getTransferLayer() !== null &&
            referenceView.getTransferLayer() == layer &&
            selectOptions["event"] == "left_click"
        //referenceView.getChildViewState() !== activeView
        ) {
            this.setActiveViewState(referenceView);
            this.moveToChildView();
        }
    };
    MapViewStateManager.prototype.selectFeatureOnCurrentView = function (layer, feature) {
        this.selectFeatureLayerOnView(this.getActiveViewState(), layer, feature, {});
    };
    MapViewStateManager.prototype.getKidoLayerByIDInView = function (layerID, view) {
        if (typeof view === "string") {
            view = this.getViewByName(view);
        }
        var kidoLayer = null;
        for (var i in view.layers) {
            if (view.layers[i].getMapLayerObject().get("layerID") == layerID) {
                kidoLayer = view.layers[i];
            }
        }
        return kidoLayer;
    };
    MapViewStateManager.prototype.getKidoLayerByIDInActiveView = function (layerID) {
        return this.getKidoLayerByIDInView(layerID, this.getActiveViewState());
    };
    MapViewStateManager.prototype.getSelectableLayers = function () {
        var layers = [];
        for (var i in this.viewStatesList) {
            for (var j in this.viewStatesList[i].layers) {
                if (this.viewStatesList[i].layers[j]
                    .getSelectableBehavior()
                    .isSelectable()) {
                    layers.push(this.viewStatesList[i].layers[j].getMapLayerObject());
                }
            }
        }
        return layers;
    };
    MapViewStateManager.prototype.toggleClorophletLayer = function (kidoLayer, visible) {
        var that = this;
        var mapLayerObject = kidoLayer.getMapLayerObject();
        if (mapLayerObject) {
            mapLayerObject.setVisible(visible);
        }
        if (visible) {
            this.map.itsOnShowcaseMode = true;
            this.hideInformationalLayers(kidoLayer);
            mapLayerObject.setOpacity(0.6);
            var vectorSource_1 = mapLayerObject.getSource();
            if (vectorSource_1.getFeatures().length) {
                this.fitToExtent(mapLayerObject.getSource().getExtent(), -1);
            }
            else {
                vectorSource_1.once("change", function (e) {
                    if (vectorSource_1.getState() === "ready") {
                        that.fitToExtent(mapLayerObject.getSource().getExtent(), -1);
                    }
                });
            }
        }
        else {
            this.zoomToActiveFeature(this.getActiveViewState());
            this.map.itsOnShowcaseMode = false;
        }
    };
    MapViewStateManager.prototype.zoomToActiveFeature = function (view) {
        if (!view) {
            view = this.getActiveViewState();
        }
        this.fitToExtent(view
            .getFeatureSelected()
            .getGeometry()
            .getExtent(), 5);
    };
    // Set visible 'false' on all informational layers, except on the layer passed as argument
    //
    MapViewStateManager.prototype.hideInformationalLayers = function (exceptLayer) {
        for (var i in this.activeInformationalLayers) {
            if (this.activeInformationalLayers[i] !== exceptLayer) {
                this.activeInformationalLayers[i].getMapLayerObject().setVisible(false);
            }
        }
    };
    MapViewStateManager.prototype.fitToFeatureExtent = function (feature, zoomLevel) {
        var extent = feature.getGeometry().getExtent();
        this.map.getViewStateManager().fitToExtent(extent, zoomLevel);
    };
    MapViewStateManager.prototype.fitToViewFeaturesSelectedExtent = function (view) {
        var allFeatures = view.getAllFeaturesSelected();
        if (allFeatures.length) {
            var extent_1 = allFeatures[0]
                .getGeometry()
                .getExtent()
                .slice(0);
            allFeatures.forEach(function (feature) {
                if (feature !== null) {
                    extend(extent_1, feature.getGeometry().getExtent());
                }
            });
            this.fitToExtent(extent_1, 9);
        }
        else {
            if (view.getBoundaryReferenceLayer()) {
                this.fitToExtent(view
                    .getBoundaryReferenceLayer()
                    .getMapLayerObject()
                    .getSource()
                    .getExtent(), -1);
            }
        }
    };
    MapViewStateManager.prototype.fitToExtent = function (extent, zoomLevel) {
        this.map.mapObject.getView().cancelAnimations(); // if the view is animating, cancel it and fit to the new extent
        this.unlistenChangeResolutionEvent();
        var currentZoom = this.map.mapObject.getView().getZoom();
        var options = { padding: this.paddingOptions, duration: 300 };
        if (zoomLevel !== -1) {
            var targetZoomFit = Math.max(zoomLevel, currentZoom);
            options["maxZoom"] = targetZoomFit;
        }
        else {
            options["size"] = this.map.mapObject.getSize();
        }
        options["callback"] = this.listenChangeResolutionEvent.bind(this);
        this.map.mapObject.getView().fit(extent, options);
    };
    MapViewStateManager.prototype.setPaddingOptions = function (padding) {
        this.paddingOptions = padding;
    };
    return MapViewStateManager;
}());
export { MapViewStateManager };
