import React, { createContext, useReducer } from "react";
import moment from "moment";
import Reducer from "./reducer";

console.log("here");

const dateFormat = "YYYY-MM-DD";
const initialState = {
  origin: "all",
  destination: "all",
  startDate: moment("2020-03-25", dateFormat).format(dateFormat),
  endDate: moment("2020-03-31", dateFormat).format(dateFormat),
  provinces: [],
  app: null,
  project: null,
  microtrips: false,
  configDates: [null, null]
};

const Store = ({ children }) => {
  const [state, dispatch]: any = useReducer(Reducer, initialState);

  return (
    <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>
  );
};

export const Context: any = createContext(initialState);

export default Store;
