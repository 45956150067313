import styled from "styled-components";
import theme from "../../theme";

export const Welcome = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Logo = styled.img`
  height: 40px;
  margin-bottom: 25px;
  cursor: pointer;
`;

export const Text = styled.p`
  margin: 25px 0 25px 0;
  color: ${theme.fontColor};
`;

export const Separator = styled.div`
  width: 100%;
  height: 25px;
`;

export const Note = styled.ul`
  margin: 0;
  padding: 120px 90px 0 50px;
  box-sizing: border-box;
  color: ${theme.fontColor};
  font-size: 11px;

  li {
    margin: 10px 0 0 25px;
  }
`;
