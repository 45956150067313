export default {
  projects: [
    {
      dates: ['2020-03-25', '2020-03-31'],
      country: 'spain',
      id: 'spaincities_barcelona',
      name: 'Spain - Barcelona',
      modules: {
        od: {
          views: [
            {
              root_view: true,
              name: 'main_od_view',
              visible: true,
              geometry_label: 'Polygon',
              layers: [
                {
                  type: 'vector',
                  id: 'main_od_layer',
                  name: 'macromobility_spain_spaincities_barcelona',
                  title: 'Spain provinces',
                  visible: true,
                  opacity: 0.3,
                  is_boundary_layer: true,
                  geom_reference_property: 'id',
                  label_reference_property: 'type',
                  select_behavior: 'origin_destination'
                }
              ]
            }
          ]
        }
      },
      map: {
        view: {
          projection: 'EPSG:3857',
          center: [224877.737215, 5072896.256202],
          zoom: 12
        }
      }
    }
  ]
};
