var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Fill, Stroke, Style, Text } from "ol/style.js";
import { NullSelectBehavior } from "./behaviors/select";
import { NullHoverBehavior } from "./behaviors/hover";
import { Vector as VectorLayer } from "ol/layer.js";
import VectorSource from "ol/source/Vector.js";
import TileLayer from "ol/layer/Tile.js";
import TileWMS from "ol/source/TileWMS.js";
import GeoJSON from "ol/format/GeoJSON.js";
var AbstractKidoLayer = /** @class */ (function () {
    function AbstractKidoLayer(layerID, layerName, layerTitle, visible, opacity, map) {
        if (visible === void 0) { visible = false; }
        if (opacity === void 0) { opacity = 1; }
        if (map === void 0) { map = null; }
        this.cql_filters = {};
        this.nameDependsOnParent = true;
        this.featureSelected = null;
        // Behaviors
        this.selectBehavior = null;
        this.hoverBehavior = null;
        this.kidoEnvironment = "kido_lucca_db";
        this.parentFeatureSelected = "";
        this.layerID = layerID;
        this.layerName = layerName;
        this.layerTitle = layerTitle;
        this.initVisibleValue = visible;
        this.initOpacityValue = opacity;
        this.mapLayerObject = null;
        this.connectParentWithCQLFilters = false;
        this.map = map;
        this.selectBehavior = this.getDefaultSelectableBehavior();
        this.hoverBehavior = this.getDefaultHoverBehavior();
    }
    AbstractKidoLayer.prototype.getKidoEnvironment = function () {
        return this.kidoEnvironment;
    };
    AbstractKidoLayer.prototype.setParentFeatureSelectedName = function (name) {
        this.parentFeatureSelected = name;
    };
    AbstractKidoLayer.prototype.getParentFeatureSelected = function () {
        return this.parentFeatureSelected;
    };
    AbstractKidoLayer.prototype.updateLayerSource = function () {
        this.mapLayerObject.setSource(this.getSource());
    };
    AbstractKidoLayer.prototype.getMapLayerObject = function () {
        if (this.mapLayerObject == null) {
            this.mapLayerObject = this.constructLayerObject();
        }
        return this.mapLayerObject;
    };
    AbstractKidoLayer.prototype.getMap = function () {
        return this.map;
    };
    AbstractKidoLayer.prototype.setMap = function (map) {
        this.map = map;
    };
    Object.defineProperty(AbstractKidoLayer.prototype, "layerName", {
        get: function () {
            return this._layerName;
        },
        set: function (value) {
            this._layerName = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AbstractKidoLayer.prototype, "geometryType", {
        get: function () {
            return this._geometryType;
        },
        set: function (value) {
            this._geometryType = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AbstractKidoLayer.prototype, "geometryIDReferenceProperty", {
        get: function () {
            return this._geometryIDReferenceProperty;
        },
        set: function (value) {
            this._geometryIDReferenceProperty = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AbstractKidoLayer.prototype, "geometryLabelReferenceProperty", {
        get: function () {
            return this._geometryLabelReferenceProperty;
        },
        set: function (value) {
            this._geometryLabelReferenceProperty = value;
        },
        enumerable: true,
        configurable: true
    });
    AbstractKidoLayer.prototype.setFeatureSelected = function (feature) {
        this.featureSelected = feature;
    };
    AbstractKidoLayer.prototype.getFeatureSelected = function () {
        return this.featureSelected;
    };
    AbstractKidoLayer.prototype.getFeatureSelectedIdentifierPropertyValue = function () {
        return this.featureSelected.get(this._geometryIDReferenceProperty);
    };
    AbstractKidoLayer.prototype.setSelectableBehavior = function (selectableBehavior) {
        this.selectBehavior = selectableBehavior;
    };
    AbstractKidoLayer.prototype.getSelectableBehavior = function () {
        return this.selectBehavior;
    };
    AbstractKidoLayer.prototype.getDefaultSelectableBehavior = function () {
        return new NullSelectBehavior(this);
    };
    AbstractKidoLayer.prototype.getDefaultHoverBehavior = function () {
        return new NullHoverBehavior(this);
    };
    AbstractKidoLayer.prototype.setHoverBehavior = function (hoverBehavior) {
        this.hoverBehavior = hoverBehavior;
    };
    AbstractKidoLayer.prototype.getHoverBehavior = function () {
        return this.hoverBehavior;
    };
    AbstractKidoLayer.prototype.resetLayerState = function () {
        this.mapLayerObject.setVisible(this.initVisibleValue);
        this.mapLayerObject.setOpacity(this.initOpacityValue);
    };
    AbstractKidoLayer.prototype.setNameDependsOnParent = function (dependsFlag) {
        this.nameDependsOnParent = dependsFlag;
    };
    AbstractKidoLayer.prototype.getNameDependsOnParent = function () {
        return this.nameDependsOnParent;
    };
    AbstractKidoLayer.prototype.getConnectParentWithCQLFilters = function () {
        return this.connectParentWithCQLFilters;
    };
    AbstractKidoLayer.prototype.setConnectParentWithCQLFilters = function (value) {
        this.connectParentWithCQLFilters = value;
    };
    AbstractKidoLayer.prototype.addCQLFilter = function (key, value, reset) {
        if (reset === void 0) { reset = false; }
        if (reset) {
            this.cql_filters = {};
        }
        this.cql_filters[key] = value;
    };
    AbstractKidoLayer.prototype.getCQLFilters = function () {
        return this.cql_filters;
    };
    AbstractKidoLayer.prototype.resetCQLFilters = function () {
        this.cql_filters = {};
    };
    AbstractKidoLayer.prototype.encodeCQLFilters = function () {
        var cqlFiltersEncoded = [];
        if (Object.keys(this.cql_filters).length) {
            for (var key in this.cql_filters) {
                cqlFiltersEncoded.push(key + "=" + "'" + this.cql_filters[key] + "'");
            }
        }
        var encodedCQL = cqlFiltersEncoded.join(" AND ");
        return encodedCQL;
    };
    return AbstractKidoLayer;
}());
export { AbstractKidoLayer };
var KidoVectorLayer = /** @class */ (function (_super) {
    __extends(KidoVectorLayer, _super);
    function KidoVectorLayer(layerID, layerName, layerTitle, visible, opacity, map) {
        if (map === void 0) { map = null; }
        return _super.call(this, layerID, layerName, layerTitle, visible, opacity, map) || this;
    }
    KidoVectorLayer.prototype.constructLayerObject = function () {
        return new VectorLayer({
            layerID: this.layerID,
            name: this.layerName,
            title: this.layerTitle,
            //style: this.getLayerStyle(),
            visible: this.initVisibleValue,
            opacity: this.initOpacityValue,
            kidoLayerObject: this,
            source: this.getSource(),
            style: function (feature, resolution) {
                var properties = feature.getProperties();
                var stroke = new Stroke({
                    color: "black",
                });
                var fill;
                if (properties["destination_selected"]) {
                    fill = new Fill({
                        color: "#F7D118",
                    });
                }
                else if (properties["origin_selected"]) {
                    fill = new Fill({
                        color: "#3E95CD",
                    });
                }
                else {
                    fill = new Fill({
                        color: "#822ed8",
                    });
                }
                return new Style({
                    fill: fill,
                    stroke: stroke,
                });
            },
        });
    };
    KidoVectorLayer.prototype.getSource = function () {
        var that = this;
        return new VectorSource({
            format: new GeoJSON(),
            url: function (extent) {
                return (that.map.getAppEnvironment().app_urls.kido_geoserver +
                    "/wfs?service=WFS&" +
                    "version=1.1.0&request=GetFeature&typename=" +
                    that.getKidoEnvironment() +
                    ":" +
                    that.getParentFeatureSelected() +
                    that.layerName +
                    "&" +
                    "outputFormat=application/json&srsname=EPSG:4326" +
                    that.constructCQLFiltersURL());
            },
        });
    };
    KidoVectorLayer.prototype.constructCQLFiltersURL = function () {
        var encodedFilters = this.encodeCQLFilters();
        if (encodedFilters) {
            encodedFilters = "&CQL_FILTER=" + encodedFilters;
        }
        else {
            encodedFilters = "";
        }
        return encodedFilters;
    };
    KidoVectorLayer.prototype.getLayerStyle = function () {
        return undefined;
    };
    return KidoVectorLayer;
}(AbstractKidoLayer));
export { KidoVectorLayer };
var KidoStatLayer = /** @class */ (function (_super) {
    __extends(KidoStatLayer, _super);
    function KidoStatLayer(layerID, layerName, layerTitle, visible, opacity, map, statEndpoints) {
        if (map === void 0) { map = null; }
        var _this = _super.call(this, layerID, layerName, layerTitle, visible, opacity, map) || this;
        _this.statsEndpoints = [];
        _this.asociatedInfoLayers = [];
        if (statEndpoints !== undefined) {
            _this.statsEndpoints = statEndpoints;
        }
        return _this;
    }
    KidoStatLayer.prototype.addStatEndpoint = function (endpoint) {
        this.statsEndpoints.push(endpoint);
    };
    KidoStatLayer.prototype.getStatsEndpoints = function () {
        return this.statsEndpoints;
    };
    KidoStatLayer.prototype.appendAsociatedInfoLayer = function (infoLayer) {
        this.asociatedInfoLayers.push(infoLayer);
    };
    KidoStatLayer.prototype.setVisibleInfoLayers = function (visible) {
        for (var i in this.asociatedInfoLayers) {
            this.asociatedInfoLayers[i].getMapLayerObject().setVisible(visible);
        }
    };
    KidoStatLayer.prototype.updateInfoLayersParams = function () {
        for (var i in this.asociatedInfoLayers) {
            this.asociatedInfoLayers[i].updateParams();
        }
    };
    return KidoStatLayer;
}(KidoVectorLayer));
export { KidoStatLayer };
var ClorophletLayer = /** @class */ (function (_super) {
    __extends(ClorophletLayer, _super);
    function ClorophletLayer(layerID, layerName, layerTitle, visible, opacity, map) {
        if (map === void 0) { map = null; }
        var _this = _super.call(this, layerID, layerName, layerTitle, visible, opacity, map) || this;
        _this.featuresWereLoaded = false;
        _this.clorophletKeyValues = {};
        _this.statEndpoint = null;
        return _this;
    }
    ClorophletLayer.prototype.getLayerStyle = function () {
        var that = this;
        return function (feature, resolution) {
            if (!that.featuresWereLoaded) {
                return [];
            }
            else {
                var featureColor = that.getVoronoiColor(feature);
                var baseStyle = new Style({
                    stroke: new Stroke({
                        color: "black",
                        width: 0.05,
                    }),
                    fill: new Fill({
                        color: featureColor,
                    }),
                    text: that.getVoronoiText(feature),
                });
                return baseStyle;
            }
        };
    };
    ClorophletLayer.prototype.getVoronoiColor = function (feature) {
        var props = feature.getProperties();
        if (props[this._geometryIDReferenceProperty] in this.clorophletKeyValues) {
            var value = this.clorophletKeyValues[feature.get(this._geometryIDReferenceProperty)] / this.maxClorophletValue;
            return value > 0.9
                ? "#B12AB8"
                : value > 0.8
                    ? "#0DD9A4"
                    : value > 0.7
                        ? "#056950"
                        : value > 0.6
                            ? "#2E9ED8"
                            : value > 0.5
                                ? "#135A7F"
                                : value > 0.4
                                    ? "#8E5BFF"
                                    : value > 0.3
                                        ? "#D82EA8"
                                        : value > 0.2
                                            ? "#761ED1"
                                            : value > 0.1
                                                ? "#30A5B3"
                                                : "#6754CC";
        }
        else {
            return "rgba(0, 0, 0, 0)";
        }
    };
    ClorophletLayer.prototype.getVoronoiText = function (feature) {
        var props = feature.getProperties();
        if (props[this._geometryIDReferenceProperty] in this.clorophletKeyValues) {
            var text = new Text({
                text: props[this._geometryIDReferenceProperty],
                font: "bold sans-serif",
                fill: new Fill({ color: "#000000" }),
            });
            return text;
        }
        else {
            return null;
        }
    };
    ClorophletLayer.prototype.setClorophletDictValues = function (dictValues) {
        this.clorophletKeyValues = dictValues;
        var max = 0;
        for (var key in this.clorophletKeyValues) {
            if (this.clorophletKeyValues[key] > max) {
                max = this.clorophletKeyValues[key];
            }
        }
        this.maxClorophletValue = max;
    };
    ClorophletLayer.prototype.getClorophletDictValues = function () {
        return this.clorophletKeyValues;
    };
    ClorophletLayer.prototype.setStatEndpoint = function (statEndpoint) {
        this.statEndpoint = statEndpoint;
    };
    ClorophletLayer.prototype.getStatEndpoint = function () {
        return this.statEndpoint;
    };
    ClorophletLayer.prototype.setFeaturesWereLoaded = function (loaded) {
        this.featuresWereLoaded = loaded;
        this.initVisibleValue = false;
        this.initOpacityValue = 0;
    };
    ClorophletLayer.prototype.getFeaturesWereLoaded = function () {
        return this.featuresWereLoaded;
    };
    return ClorophletLayer;
}(KidoVectorLayer));
export { ClorophletLayer };
var AbstractInfoLayer = /** @class */ (function (_super) {
    __extends(AbstractInfoLayer, _super);
    function AbstractInfoLayer(layerID, layerName, layerTitle, visible, opacity, styleName, map) {
        if (map === void 0) { map = null; }
        var _this = _super.call(this, layerID, layerName, layerTitle, visible, opacity, map) || this;
        _this.envVariables = {};
        _this.layerStyleName = styleName;
        return _this;
    }
    AbstractInfoLayer.prototype.constructLayerObject = function () {
        return new TileLayer({
            layerID: this.layerID,
            name: this.layerName,
            title: this.layerTitle,
            visible: this.initVisibleValue,
            opacity: 0.3,
            kidoLayerObject: this,
            source: this.getSource(),
        });
    };
    AbstractInfoLayer.prototype.getSource = function () {
        var that = this;
        return new TileWMS({
            url: that.map.getAppEnvironment().app_urls.kido_geoserver + "/wms",
            params: {
                LAYERS: this.getKidoEnvironment() +
                    ":" +
                    this.getParentFeatureSelected() +
                    this.layerName,
                TILED: true,
                STYLES: this.getLayerStyle(),
            },
            serverType: "geoserver",
            transition: 0,
        });
    };
    AbstractInfoLayer.prototype.setEnvVariable = function (variableName, value) {
        this.envVariables[variableName] = value;
    };
    AbstractInfoLayer.prototype.getEnvVariable = function (variableName) {
        return this.envVariables[variableName];
    };
    AbstractInfoLayer.prototype.encodeEnvVariables = function () {
        var encodedEnvVars = "";
        for (var key in this.envVariables) {
            encodedEnvVars += key + ":" + this.envVariables[key] + ";";
        }
        return encodedEnvVars;
    };
    AbstractInfoLayer.prototype.appendFilter = function (key, value) {
        return 'AND "' + key + '"=' + "'" + value + "'";
    };
    AbstractInfoLayer.prototype.updateParams = function () {
        this.getMapLayerObject().getSource().updateParams(this.getParamsDict());
    };
    AbstractInfoLayer.prototype.getLayerStyle = function () {
        return this.layerStyleName;
    };
    return AbstractInfoLayer;
}(AbstractKidoLayer));
export { AbstractInfoLayer };
var VoronoiRoutesInfoLayer = /** @class */ (function (_super) {
    __extends(VoronoiRoutesInfoLayer, _super);
    function VoronoiRoutesInfoLayer(layerID, layerName, layerTitle, visible, opacity, styleName, referenceField, map) {
        if (map === void 0) { map = null; }
        var _this = _super.call(this, layerID, layerName, layerTitle, visible, opacity, styleName, map) || this;
        _this.referenceField = referenceField;
        return _this;
        //this.setEnvVariable("first_gradient_color","#00FF00");
    }
    VoronoiRoutesInfoLayer.prototype.getParamsDict = function () {
        var filters = { cql_filter: this.encodeFilters() }; //,'env':'middle_gradient_color:#000000;last_gradient_color:#FF00FF;'}
        if (Object.keys(this.envVariables).length) {
            filters["env"] = this.encodeEnvVariables();
        }
        return filters;
    };
    VoronoiRoutesInfoLayer.prototype.encodeFilters = function () {
        return this.encodeCQLFilters();
    };
    VoronoiRoutesInfoLayer.prototype.setReferenceFieldValue = function (value) {
        this.cql_filters[this.referenceField] = value;
    };
    return VoronoiRoutesInfoLayer;
}(AbstractInfoLayer));
export { VoronoiRoutesInfoLayer };
///  ADVERTS LAYER  //////////////
var AdvertsStreetsInfoLayer = /** @class */ (function (_super) {
    __extends(AdvertsStreetsInfoLayer, _super);
    function AdvertsStreetsInfoLayer() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AdvertsStreetsInfoLayer.prototype.getParamsDict = function () {
        return { cql_filter: this.encodeFilters() };
    };
    AdvertsStreetsInfoLayer.prototype.encodeFilters = function () {
        var filters = this.map.getFeatureFilters();
        var encodedFilter = '"id" = ' + filters["ad_selected"] + "";
        return encodedFilter;
    };
    return AdvertsStreetsInfoLayer;
}(AbstractInfoLayer));
export { AdvertsStreetsInfoLayer };
// FACTORY ////////
var KidoLayerFactory = /** @class */ (function () {
    function KidoLayerFactory() {
    }
    KidoLayerFactory.createKidoLayer = function (config) {
        if (config.type == "vector") {
            return new KidoVectorLayer(config.id, config.name, config.title, config.visible, config.opacity);
        }
    };
    return KidoLayerFactory;
}());
export { KidoLayerFactory };
