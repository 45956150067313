import React, { useContext, useEffect, useState } from "react";
import { KidoMapFactory } from "@kido/map";
import { EnvironmentImpl } from "./Map.environment";
import ProjectConfig from "../../apps_config/brazil_provinces.config";
import { Context } from "../../store";
import moment from "moment";

const Map = (props) => {
  const [state, dispatch]: any = useContext(Context);

  const onOriginSelected = (prov_id) => {
    dispatch({
      type: "SET_ORIGIN",
      payload: prov_id === "all" ? prov_id : +prov_id
    });
  };

  const onDestinationSelected = (prov_id) => {
    dispatch({
      type: "SET_DESTINATION",
      payload: prov_id === "all" ? prov_id : +prov_id
    });
  };

  const loadAppConfig = (app_name) => {
    let config = require("../../apps_config/" + app_name + ".config").default;
    return config;
  };

  useEffect(() => {
    let appConfig = loadAppConfig(props.app_name);
    const map = KidoMapFactory.createKidoMap(new EnvironmentImpl(), appConfig);
    map.initMap(props.mapID, "dark");
    dispatch({ type: "SET_MAP", payload: map });

    let config_dates = appConfig.projects[0].dates || [
      //moment().subtract(15, "days").format("YYYY-MM-DD"),
      //moment().format("YYYY-MM-DD")
      "2020-01-01",
      "2020-01-15"
    ];

    dispatch({ type: "SET_CONFIG_DATES", payload: config_dates });
  }, []);

  useEffect(() => {
    if (state.map) {
      // PROVINCE VIEW EVENTS
      let main_od_view = state.map
        .getViewStateManager()
        .getViewByName("main_od_view");
      main_od_view.viewEventHandler.OnBoundaryLayerUpdated.on(() => {
        let main_od_layer = state.map
          .getViewStateManager()
          .getKidoLayerByIDInView("main_od_layer", "main_od_view");
        let availableODPolygons = [];
        main_od_layer
          .getMapLayerObject()
          .getSource()
          .forEachFeature(function (feature) {
            let fName = feature
              .get(main_od_layer._geometryLabelReferenceProperty)
              .toString();
            availableODPolygons.push({
              name: fName,
              code: fName,
              id: parseInt(
                feature.get(main_od_layer._geometryIDReferenceProperty)
              ).toString()
            });
          });

        dispatch({
          type: "SET_PROVINCES",
          payload: availableODPolygons
        });
      });

      main_od_view.viewEventHandler.OnFeatureClicked.on(() => {
        let lastSelectedPolygonType = main_od_view
          .getLayerSelected()
          .getSelectableBehavior()
          .getLastSelectedPolygonType();
        let lastSelectedEventType = main_od_view
          .getLayerSelected()
          .getSelectableBehavior()
          .getLastSelectedEventType();
        let target_id =
          lastSelectedEventType === "deselect"
            ? "all"
            : main_od_view
                .getLayerSelected()
                .getFeatureSelectedIdentifierPropertyValue();
        if (lastSelectedPolygonType == "origin") {
          onOriginSelected(target_id);
        } else {
          onDestinationSelected(target_id);
        }
      });
    }
  }, [state.map]);

  useEffect(() => {}, [state.origin]);

  return <div id={props.mapID}></div>;
};

export default Map;
