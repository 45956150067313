var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var Line = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  height: auto;\n  position: relative;\n\n  .toggleBtn {\n    background: none;\n    border: none;\n    cursor: pointer;\n    font-size: 12px;\n  }\n"], ["\n  width: 100%;\n  height: auto;\n  position: relative;\n\n  .toggleBtn {\n    background: none;\n    border: none;\n    cursor: pointer;\n    font-size: 12px;\n  }\n"])));
export var LineMetadata = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-bottom: 25px;\n  color: ", ";\n  font-size: 14px;\n  margin-left: 35px;\n  font-weight: bolder;\n"], ["\n  margin-bottom: 25px;\n  color: ", ";\n  font-size: 14px;\n  margin-left: 35px;\n  font-weight: bolder;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.fontColor;
});
export var Logo = styled.img(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  height: 25px;\n  position: absolute;\n  top: 0;\n  left: 0;\n"], ["\n  height: 25px;\n  position: absolute;\n  top: 0;\n  left: 0;\n"])));
export var ToggleButton = styled.button(templateObject_4 || (templateObject_4 = __makeTemplateObject([""], [""])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
