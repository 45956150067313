import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Store from "./store";
import Panel from "./components/Panel";
import Map from "./components/Map";
import Helper from "./components/Helper";
import Landing from "./components/Landing";
import Switcher from "./components/Switcher";
import "./App.css";

const Auth = {
  isAuthenticated: false,
  authenticate(cb) {
    Auth.isAuthenticated = true;
  },
  signout(cb) {
    Auth.isAuthenticated = false;
  }
};

const PrivateRoute = ({ children, ...rest }) => {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        Auth.isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
};

const App = () => {
  return (
    <Store>
      <Switch>
        <PrivateRoute exact path="/spain">
          <Map mapID="map" app_name="spain_provinces"></Map>
          <Panel app="spain" country="spain" project="provinces"></Panel>
          <Switcher></Switcher>
          <Helper></Helper>
        </PrivateRoute>
        <PrivateRoute exact path="/kuwait-districts">
          <Map mapID="map" app_name="kuwait_districts"></Map>
          <Panel app="kuwait" country="kuwait" project="kuwait_districts"></Panel>
          <Switcher></Switcher>
          <Helper></Helper>
        </PrivateRoute>
        <PrivateRoute exact path="/brazil-parana-parana">
          <Map mapID="map" app_name="brazil_parana_parana"></Map>
          <Panel
            app="Parana"
            country="brazil"
            project="brazil_parana_parana"
          ></Panel>
          <Switcher></Switcher>
          <Helper></Helper>
        </PrivateRoute>
        <PrivateRoute exact path="/brazil-parana-curitiba">
          <Map mapID="map" app_name="brazil_parana_curitiba"></Map>
          <Panel
            app="Curitiba"
            country="brazil"
            project="brazil_parana_curitiba"
          ></Panel>
          <Switcher></Switcher>
          <Helper></Helper>
        </PrivateRoute>
        <PrivateRoute exact path="/brazil-ceara-ceara">
          <Map mapID="map" app_name="brazil_ceara_ceara"></Map>
          <Panel
            app="Ceara"
            country="brazil"
            project="brazil_ceara_ceara"
          ></Panel>
          <Switcher></Switcher>
          <Helper></Helper>
        </PrivateRoute>
        <PrivateRoute exact path="/brazil-ceara-fortaleza">
          <Map mapID="map" app_name="brazil_ceara_fortaleza"></Map>
          <Panel
            app="Fortaleza"
            country="brazil"
            project="brazil_ceara_fortaleza"
          ></Panel>
          <Switcher></Switcher>
          <Helper></Helper>
        </PrivateRoute>
        <Route path="/">
          <Landing auth={Auth} />
        </Route>
      </Switch>
    </Store>
  );
};

export default App;
