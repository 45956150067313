import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Welcome, Text, Note } from "./Welcome.styles";
import Provinces from "../Provinces";
import Logo from "../Logo";

const WelcomeCmp = () => {
  const { t } = useTranslation();

  return (
    <Welcome>
      <Logo height={80} />
      <Text>{t("Select a origin")}</Text>
      <Provinces type="origin" />
      <Text>{t("orDestination")}</Text>
      <Provinces type="destination" />
      <Note>
        <strong>Technical Note</strong>
        <li>
          Estimations of trips based in daily updates from multiple sources,
          public and private data.
        </li>
        <li>
          A trip is defined as any displacement between two stops, by any mode,
          of any type.
        </li>
        <li>
          The minimum distance for a trip is between 50 and 250 meters in dense
          urban areas, between 250 and 500 meters in suburban areas, and from
          500 meters to 1 km out of urban areas, approximately.
        </li>
        <li>
          A stop is a lack of movement or a displacement smaller than a minimum
          distance in a significant amount of time.
        </li>
        <li>
          Data is aggregated with differential privacy tools including random
          fluctuations and shuffle.
        </li>
        <li>Minimum aggregation threshold is 10 people.</li>
        <li>
          This data should be use for trends’ evolution and estimation of
          magnitudes only.
        </li>
        <li>
          For any technical or commercial question, contact at{" "}
          <a href="mailto:info@kidodynamics.com">info@kidodynamics.com</a>
        </li>
      </Note>
    </Welcome>
  );
};

export default WelcomeCmp;
