import styled from "styled-components";
import { Select } from "antd";

export const ProvinceSelect = styled(Select)`
  .ant-select-selection__placeholder {
    margin-top: -8px !important;
  }

  .ant-select-selection-selected-value {
    margin-top: 2px !important;
  }
`;
