const Reducer = (state, action) => {
  switch (action.type) {
    case 'SET_ORIGIN':
      return {
        ...state,
        origin: action.payload
        //destination: "all"
      };
    case 'SET_DESTINATION':
      return {
        ...state,
        destination: action.payload
      };
    case 'SET_CONFIG_DATES':
      return {
        ...state,
        configDates: action.payload,
        startDate: action.payload[0],
        endDate: action.payload[1]
      };
    case 'SET_DATE':
      return {
        ...state,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate
      };
    case 'SET_PROVINCES':
      return {
        ...state,
        provinces: action.payload
      };
    case 'SET_MAP':
      return {
        ...state,
        map: action.payload
      };
    case 'SET_APP':
      return {
        ...state,
        app: action.payload.app,
        project: action.payload.project,
        country: action.payload.country
      };
    case 'SET_FLIP':
      return {
        ...state,
        origin: state.destination,
        destination: state.origin
      };
    case 'SET_MICROTRIPS':
      return {
        ...state,
        microtrips: action.payload
      };
    default:
      return state;
  }
};

export default Reducer;
