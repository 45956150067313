import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Modal, Input, Button } from "antd";
import { useTranslation } from "react-i18next";
import { Separator } from "./Auth.styles";
import users from "../../data/users";

const Auth = ({ modal, onClose, login }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const [auth, setAuth] = useState({ user: null, pass: null });

  const onLogin = () => {};

  const onChange = field => e => {
    setAuth({
      ...auth,
      [field]: e.target.value
    });
  };

  const onAuth = () => {
    if (
      auth.user === users[modal.trigger].user &&
      auth.pass === users[modal.trigger].pass
    ) {
      login.isAuthenticated = true;
      history.push(`/${modal.trigger}`);
    }
  };

  return (
    <Modal
      title={t("login")}
      visible={modal.open}
      onOk={onAuth}
      onCancel={onClose}
      footer={[
        <Button key="back" onClick={onClose}>
          {t("cancel")}
        </Button>,
        <Button key="submit" type="primary" onClick={onAuth}>
          {t("ok")}
        </Button>
      ]}
    >
      <Input
        onChange={onChange("user")}
        placeholder={t("username")}
        maxLength={25}
      />
      <Separator />
      <Input
        onChange={onChange("pass")}
        placeholder={t("password")}
        type="password"
        maxLength={25}
      />
    </Modal>
  );
};

export default Auth;
