export var toThousands = function (locale, x) {
    return x
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "" + (locale === "es" ? "$1." : "$1,"));
};
export var isWeekend = function (date) {
    var d = new Date(date);
    var day = d.getUTCDay();
    return day === 6 || day === 0;
};
export var sortNumber = function (a, b) { return b.value - a.value; };
