import React, { useContext, Fragment, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  PanelWrapper,
  PanelHolder,
  PanelContent,
  PanelHeader,
  PanelLogo,
} from "./Panel.styles";
import Filters from "../Filters";
import { Context } from "../../store";
import Welcome from "../Welcome";
import Trip from "../Trip";
import Logo from "../Logo";
import Info from "../Info";

const Plots = () => {
  const history = useHistory();

  return (
    <Fragment>
      <PanelHeader>
        <Logo height={50} />
      </PanelHeader>
      <PanelContent>
        <Filters />
        <Trip />
      </PanelContent>
    </Fragment>
  );
};

const Panel = ({ app, project, country }) => {
  const [state, dispatch]: any = useContext(Context);

  useEffect(() => {
    dispatch({ type: "SET_APP", payload: { app, project, country } });
  }, []);

  return (
    <PanelWrapper open={true}>
      <div className="tooltip"></div>
      <PanelHolder>
        {state.origin === "all" && state.destination === "all" ? (
          <Welcome />
        ) : (
          <Plots />
        )}
      </PanelHolder>
    </PanelWrapper>
  );
};

export default Panel;
