import styled from 'styled-components';
import theme from '../../theme';

export const Holder = styled.div`
  width: 100%;
  height: 100%;
  background: #23232e;
`;

export const Landing = styled.div`
  width: 900px;
  height: 100%;
  margin: 0 auto;
  background: #23232e;
`;

export const LandingLogoHolder = styled.div`
  text-align: center;
  padding-top: 90px;
`;

export const LandingMsg = styled.p`
  color: rgba(255, 255, 255, 0.7);
  padding-top: 10px;
  font-size: 22px;
`;

export const LandingMenu = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  .landingMenuItem {
    width: 22%;
    height: 200px;
    background: gray;
    margin-top: 90px;
    margin-right: 20px;
    border: none;
    cursor: pointer;
    position: relative;
    border: 6px solid transparent;

    &:hover {
      border: 6px solid rgba(255, 255, 255, 0.2);
    }

    span {
      color: rgba(255, 255, 255, 0.7);
      position: relative;
      bottom: -120px;
      text-align: center;
      display: block;
    }
  }

  .spainLogo {
    background: url('./spain.map.png') no-repeat center center;
    border-radius: 6px;
  }

  .brazilLogo {
    background: url('./brazil.map.png') no-repeat center center;
    border-radius: 6px;
  }

  .kuwaitLogo {
    background: url('./kuwait.jpg') no-repeat center center;
    border-radius: 6px;
  }
`;

export const LandingLogo = styled.img`
  height: 60px;
`;
