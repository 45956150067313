var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var AbstractHoverBehavior = /** @class */ (function () {
    function AbstractHoverBehavior(referenceLayer) {
        this.referenceLayer = referenceLayer;
        this.referenceLayer.setHoverBehavior(this);
    }
    return AbstractHoverBehavior;
}());
export { AbstractHoverBehavior };
var NullHoverBehavior = /** @class */ (function (_super) {
    __extends(NullHoverBehavior, _super);
    function NullHoverBehavior(referenceLayer) {
        return _super.call(this, referenceLayer) || this;
    }
    NullHoverBehavior.prototype.onHoverAction = function (evt, feature) {
        throw new Error("Method not implemented.");
    };
    NullHoverBehavior.prototype.isHoverable = function () {
        return false;
    };
    return NullHoverBehavior;
}(AbstractHoverBehavior));
export { NullHoverBehavior };
var TooltipHoverBehavior = /** @class */ (function (_super) {
    __extends(TooltipHoverBehavior, _super);
    function TooltipHoverBehavior(referenceLayer) {
        return _super.call(this, referenceLayer) || this;
    }
    TooltipHoverBehavior.prototype.isHoverable = function () {
        return true;
    };
    TooltipHoverBehavior.prototype.updateTooltipMessage = function (coordinates, message) {
        this.getHoverTooltipElement().innerHTML = message;
        this.getHoverTooltipOverlay().setPosition(coordinates);
        this.getHoverTooltipElement().classList.remove('hidden');
    };
    TooltipHoverBehavior.prototype.getHoverTooltipElement = function () {
        return this.referenceLayer.getMap().getTooltipHoverBehaviorElement();
    };
    TooltipHoverBehavior.prototype.getHoverTooltipOverlay = function () {
        return this.referenceLayer.getMap().getTooltipHoverBehaviorOverlay();
    };
    return TooltipHoverBehavior;
}(AbstractHoverBehavior));
export { TooltipHoverBehavior };
var TooltipFeatureLabelHoverBehavior = /** @class */ (function (_super) {
    __extends(TooltipFeatureLabelHoverBehavior, _super);
    function TooltipFeatureLabelHoverBehavior() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TooltipFeatureLabelHoverBehavior.prototype.onHoverAction = function (evt, feature) {
        this.updateTooltipMessage(evt.coordinate, feature.get(this.referenceLayer._geometryLabelReferenceProperty));
    };
    return TooltipFeatureLabelHoverBehavior;
}(TooltipHoverBehavior));
export { TooltipFeatureLabelHoverBehavior };
var KidoHoverBehaviorFactory = /** @class */ (function () {
    function KidoHoverBehaviorFactory() {
    }
    KidoHoverBehaviorFactory.createHoverBehavior = function (config) {
        if (config.type == 'tooltip_feature_label') {
            return new TooltipFeatureLabelHoverBehavior(config.layer);
        }
        else {
            return new NullHoverBehavior(config.layer);
        }
    };
    return KidoHoverBehaviorFactory;
}());
export { KidoHoverBehaviorFactory };
