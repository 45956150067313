var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';
import moment from 'moment';
import { Line, Logo, LineMetadata } from './Line.styles';
import { toThousands } from '../helper';
var LineCmp = function (_a) {
    /*   const style = {
      width: isDesktopOrLaptop ? 725 : 310,
      height: 900
    }; */
    var data = _a.data, theme = _a.theme, locale = _a.locale, isDesktopOrLaptop = _a.isDesktopOrLaptop, config = _a.config, title = _a.title, subtitle = _a.subtitle, style = _a.style, id = _a.id;
    var d3Container = useRef(null);
    var clicked = useRef(false);
    var margin = { top: 10, right: 20, bottom: 30, left: 30 };
    var width = style.width - margin.left - margin.right;
    var height = style.height - margin.top - margin.bottom;
    var color = d3.scaleOrdinal().range(theme.colors);
    var graphWidth = isDesktopOrLaptop ? style.width - 75 : 240;
    var graphHeight = isDesktopOrLaptop ? 400 : 200;
    var tooltip = d3.select('.tooltip');
    var circleOpacity = 1;
    var circleRadius = 4;
    var activeDates = useRef([]);
    useEffect(function () {
        d3.select(d3Container.current).select('svg').remove();
        var baseSVG = d3
            .select(d3Container.current)
            .append('svg')
            .attr('width', width + margin.left + margin.right)
            .attr('height', height + margin.top + margin.bottom);
        //.attr("transform", `translate(${margin.left},${margin.top})`);
        var svg = baseSVG
            .append('g')
            .attr('width', graphWidth)
            .attr('height', graphHeight)
            .attr('transform', "translate(" + (isDesktopOrLaptop ? 50 : 40) + "," + (isDesktopOrLaptop ? margin.top : 0) + ")");
        var parseTime = d3.timeParse('%H:%M');
        data.forEach(function (e, i) {
            e.values.forEach(function (d) {
                d[config[0]] = parseTime(d[config[0]]);
                d[config[1]] = +d[config[1]];
                d.group = e.name;
            });
        });
        var xScale = d3
            .scaleTime()
            .domain([
            d3.min(data, function (co) { return d3.min(co.values, function (d) { return d[config[0]]; }); }),
            d3.max(data, function (co) { return d3.max(co.values, function (d) { return d[config[0]]; }); })
        ])
            .range([0, graphWidth]);
        var yScale = d3
            .scaleLinear()
            .domain([
            d3.min(data, function (co) { return d3.min(co.values, function (d) { return d[config[1]]; }); }),
            d3.max(data, function (co) { return d3.max(co.values, function (d) { return d[config[1]]; }); })
        ])
            .range([graphHeight, 0]);
        var make_x_gridlines = function () {
            return d3.axisBottom(xScale);
        };
        var make_y_gridlines = function () {
            return d3.axisLeft(yScale);
        };
        var line = d3
            .line()
            .x(function (d) { return xScale(d[config[0]]); })
            .y(function (d) { return yScale(d[config[1]]); })
            .curve(d3.curveCatmullRom.alpha(0.5));
        var lines = svg.append('g').attr('class', 'lines');
        lines
            .selectAll("." + id + "-line-group")
            .data(data)
            .enter()
            .append('g')
            .attr('class', function (d, i) { return id + "-line-group line-" + d.name + "-" + id; })
            .append('path')
            .attr('class', 'line')
            .style('stroke-width', '1.5px')
            .attr('d', function (d) { return line(d.values); })
            .attr('fill', 'none')
            .style('stroke', function (d, i) { return color(i); });
        var xAxis = d3.axisBottom(xScale).ticks(24);
        var yAxis = d3.axisLeft(yScale);
        svg
            .append('g')
            .attr('class', 'x axis')
            .attr('transform', "translate(0, " + graphHeight + ")")
            .call(xAxis)
            .selectAll('text')
            .text(function (d) { return moment(d).get('hour') + ":00"; })
            .attr('transform', 'rotate(-45)')
            .style('text-anchor', 'end')
            .style('fill', theme.fontColor);
        svg.selectAll('.x.axis line').attr('stroke', theme.fontColor).attr('opacity', 0.1);
        svg
            .append('g')
            .attr('class', 'y axis')
            .attr('transform', "translate(0, 0)")
            .call(yAxis)
            .selectAll('text')
            .attr('fill', theme.fontColor);
        svg.selectAll('.y.axis line').attr('stroke', theme.fontColor).attr('opacity', 0.1);
        svg
            .append('g')
            .attr('class', 'grid')
            .attr('transform', 'translate(0,' + graphHeight + ')')
            .call(make_x_gridlines().tickSize(-graphHeight).tickFormat(''))
            .selectAll('line')
            .attr('stroke', theme.fontColor)
            .attr('opacity', 0.1);
        svg
            .append('g')
            .attr('class', 'grid')
            .call(make_y_gridlines().tickSize(-width).tickFormat(''))
            .selectAll('line')
            .attr('stroke', theme.fontColor)
            .attr('opacity', 0.1);
        svg.selectAll('.domain').style('display', 'none');
        lines
            .selectAll('circle-group')
            .data(data)
            .enter()
            .append('g')
            .style('fill', function (d, i) { return color(i); })
            .selectAll("." + id + "-circle")
            .data(function (d) { return d.values; })
            .enter()
            .append('g')
            .attr('class', function (d) { return id + "-circle circle-" + d.group + "-" + id; })
            .on('mouseover', function (d, i, elements) {
            tooltip.transition().duration(200).style('opacity', 0.7);
            tooltip
                .html("<div class=\"tooltip-inner\">" + toThousands(locale, d[config[1]]) + "</div>")
                .style('left', d3.event.pageX + 'px')
                .style('top', d3.event.pageY - 28 + 'px');
            d3.selectAll(elements).filter(':hover').style('cursor', 'pointer');
        })
            .on('mouseout', function (d, i, elements) {
            tooltip.transition().duration(500).style('opacity', 0);
            d3.selectAll(elements).style('fill-opacity', 1);
        })
            .append('circle')
            .attr('cx', function (d) { return xScale(d[config[0]]); })
            .attr('cy', function (d) { return yScale(d[config[1]]); })
            .attr('r', circleRadius)
            .style('opacity', circleOpacity)
            .on('mouseover', function (d) { })
            .on('mouseout', function (d) { });
        var legend = baseSVG
            .append('g')
            .attr('class', 'legend')
            .attr('transform', "translate(30, " + (graphHeight + 70) + ")");
        var lg = legend
            .selectAll('g')
            .data(data)
            .enter()
            .append('g')
            .attr('transform', function (d, i) {
            if (style.width > 700) {
                if (i <= 4) {
                    return "translate(" + i * 200 + ", 0)";
                }
                else if (i > 4 && i <= 9) {
                    return "translate(" + (i - 5) * 200 + ", 30)";
                }
                else if (i > 9 && i <= 14) {
                    return "translate(" + (i - 10) * 200 + ", 60)";
                }
                else if (i > 14 && i <= 19) {
                    return "translate(" + (i - 15) * 200 + ", 90)";
                }
                else if (i > 19 && i <= 24) {
                    return "translate(" + (i - 20) * 200 + ", 120)";
                }
            }
            else {
                if (isDesktopOrLaptop) {
                    if (i <= 2) {
                        return "translate(" + i * 200 + ", 0)";
                    }
                    else if (i > 2 && i <= 5) {
                        return "translate(" + (i - 3) * 200 + ", 30)";
                    }
                    else if (i > 5 && i <= 8) {
                        return "translate(" + (i - 6) * 200 + ", 60)";
                    }
                    else if (i > 8 && i <= 11) {
                        return "translate(" + (i - 9) * 200 + ", 90)";
                    }
                    else if (i > 11 && i <= 14) {
                        return "translate(" + (i - 12) * 200 + ", 120)";
                    }
                    else if (i > 14 && i <= 17) {
                        return "translate(" + (i - 15) * 200 + ", 150)";
                    }
                    else if (i > 17 && i <= 20) {
                        return "translate(" + (i - 18) * 200 + ", 180)";
                    }
                    else if (i > 20 && i <= 23) {
                        return "translate(" + (i - 21) * 200 + ", 210)";
                    }
                    else if (i > 23 && i <= 26) {
                        return "translate(" + (i - 24) * 200 + ", 240)";
                    }
                    else if (i > 26 && i <= 29) {
                        return "translate(" + (i - 27) * 200 + ", 270)";
                    }
                    else if (i > 29 && i <= 32) {
                        return "translate(" + (i - 30) * 200 + ", 300)";
                    }
                    else {
                        return "translate(0, 300)";
                    }
                }
                else {
                    return "translate(0, " + i * 20 + ")";
                }
            }
        })
            .on('mouseover', function (d, i, elements) {
            d3.selectAll(elements).filter(':hover').style('cursor', 'pointer');
        })
            .on('click', function (d) {
            var element = d.name;
            activeDates.current = activeDates.current.includes(element)
                ? activeDates.current.filter(function (d) { return d !== element; })
                : __spreadArrays(activeDates.current, [element]);
            d3.selectAll("." + id + "-line-group").style('opacity', 0.1);
            d3.selectAll("." + id + "-circle").style('opacity', 0.1);
            d3.selectAll("." + id + "-legend-text").style('opacity', 0.2);
            activeDates.current.forEach(function (el) {
                d3.select(".line-" + el + "-" + id).style('opacity', 1);
                d3.select(".legend-" + el + "-" + id).style('opacity', 1);
                d3.selectAll(".circle-" + el + "-" + id).style('opacity', 1);
            });
        });
        lg.append('rect')
            .style('fill', function (d, i) { return color(i); })
            .attr('x', 0)
            .attr('y', 0)
            .attr('width', 10)
            .attr('height', 10);
        lg.append('text')
            .style('fill', theme.fontColor)
            .attr('x', 17.5)
            .attr('y', 10)
            .style('font-size', '12px')
            .attr('class', function (d) { return id + "-legend-text legend-" + d.name + "-" + id; })
            .text(function (d) {
            return moment(d.name).format(theme.dateFormat) + " - " + toThousands(locale, d.values.reduce(function (a, c) { return parseInt(a) + parseInt(c[config[1]]); }, 0)) + " " + subtitle;
        });
    }, [d3Container.current, data]);
    return (React.createElement(Line, null,
        React.createElement(Logo, { src: theme.type === 'dark' ? './KIDO_squared_white.png' : './kido-squared-black.png' }),
        title && React.createElement(LineMetadata, { theme: theme }, title),
        React.createElement("div", { ref: d3Container, className: "line", style: style })));
};
export default LineCmp;
