export default {
  projects: [
    {
      dates: ['2020-02-01', '2020-03-25'],
      country: 'brazil',
      id: 'ceara_ceara',
      name: 'Brazil - Ceará',
      modules: {
        od: {
          views: [
            {
              root_view: true,
              name: 'main_od_view',
              visible: true,
              geometry_label: 'Cell',
              layers: [
                {
                  type: 'vector',
                  id: 'main_od_layer',
                  name: 'macromobility_brazil_ceara_ceara',
                  title: 'Brazil - Ceará',
                  visible: true,
                  opacity: 0.3,
                  is_boundary_layer: true,
                  geom_reference_property: 'id',
                  label_reference_property: 'name',
                  select_behavior: 'origin_destination',
                  hover_behavior: 'tooltip_feature_label'
                }
              ]
            }
          ]
        }
      },
      map: {
        view: {
          projection: 'EPSG:3857',
          center: [-4589890.674468, -587036.37723],
          zoom: 8
        },
        enable_hover_behaviors: true
      }
    }
  ]
};
