import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { Context } from "../../store";
import { Switch, SwitchHolder } from "./Switcher.styles";
import alpha from "../../data/alpha";

const apps = [
  {
    value: "/spain",
    label: "España",
  },
  {
    value: "/kuwait-districts",
    label: "Kuwait",
  },
  {
    value: "/brazil-parana-parana",
    label: "Paraná",
  },
  {
    value: "/brazil-parana-curitiba",
    label: "Curitiba",
  },
  {
    value: "/brazil-ceara-ceara",
    label: "Ceará",
  },
  {
    value: "/brazil-ceara-fortaleza",
    label: "Fortaleza",
  },
];

const Switcher = (props) => {
  const [state, dispatch]: any = useContext(Context);
  const sortAlpha = alpha("label");
  let history = useHistory();

  const onChange = (value) => (window.location.href = value);

  const base = {
    style: { width: "100px", height: "35px" },
    onChange: onChange,
    value: state.app,
  };

  return (
    <SwitchHolder>
      <Switch {...base}>
        {apps.sort(sortAlpha).map((app) => (
          <Switch.Option value={app.value} key={app.value}>
            {app.label}
          </Switch.Option>
        ))}
      </Switch>
    </SwitchHolder>
  );
};

export default Switcher;
