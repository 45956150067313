import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Logo } from "./Logo.styles";

const LogoCmp = ({ height }) => {
  const location = useLocation();
  const history = useHistory();

  return (
    <Logo
      height={height}
      src="./KIDO_horizontal_white.png"
      onClick={() => history.push("/")}
    ></Logo>
  );
};

export default LogoCmp;
