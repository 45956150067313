export default {
  projects: [
    {
      //dates: ['2020-06-10', '2020-06-24'],
      country: 'spain',
      id: 'provinces',
      name: 'Spain provinces',
      modules: {
        od: {
          views: [
            {
              root_view: true,
              name: 'main_od_view',
              visible: true,
              geometry_label: 'Province',
              layers: [
                {
                  type: 'vector',
                  id: 'main_od_layer',
                  name: 'tourismapp_spain_all_provinces',
                  title: 'Spain provinces',
                  visible: true,
                  opacity: 0.3,
                  is_boundary_layer: true,
                  geom_reference_property: 'id_prov',
                  label_reference_property: 'name',
                  select_behavior: 'origin_destination',
                  hover_behavior: 'tooltip_feature_label'
                }
              ]
            }
          ]
        }
      },
      map: {
        view: {
          projection: 'EPSG:3857',
          center: [-777823.19983, 4879739.885726],
          zoom: 7
        },
        enable_hover_behaviors: true
      }
    }
  ]
};
