import React, { useRef, useEffect } from 'react';
import { Statistic, Icon } from 'antd';
import * as d3 from 'd3';
import { Pie, PieMetadata, Logo, PieChild } from './Pie.styles';
import { toThousands } from '../helper';
var PieCmp = function (_a) {
    var data = _a.data, id = _a.id, onClick = _a.onClick, metadata = _a.metadata, theme = _a.theme, locale = _a.locale, isDesktopOrLaptop = _a.isDesktopOrLaptop, style = _a.style, direction = _a.direction, children = _a.children;
    var d3Container = useRef(null);
    var margin = { top: 0, right: 0, bottom: 0, left: 0 };
    var width = style.width - margin.left - margin.right;
    var height = style.height - margin.top - margin.bottom;
    var radius = direction === 'column' ? Math.min(width - 150, height - 150) : Math.min(width - 100, height - 100) / 2;
    var tooltip = d3.select('.tooltip');
    var color = d3.scaleOrdinal().range(theme.colors);
    var tweenPie = function (b) {
        b.innerRadius = 0;
        var i = d3.interpolate({ startAngle: 0, endAngle: 0 }, b);
        return function (t) {
            return arc(i(t));
        };
    };
    var arc = d3
        .arc()
        .outerRadius(radius - 10)
        .innerRadius(0);
    var arcOver = d3.arc().outerRadius(radius).innerRadius(0);
    var getYAxis = function (selection) { return selection.attr('y', function (d, i) { return "" + 25 * i; }); };
    var pie = d3
        .pie()
        //.sort((d, a) => d.value - a.value)
        .sort(null)
        .value(function (d) { return d.value; });
    useEffect(function () {
        d3.select(d3Container.current).select('svg').remove();
        var baseSVG = d3.select(d3Container.current).append('svg').attr('width', width).attr('height', height);
        var pieConfig = direction === 'column'
            ? {
                x: width / 2,
                y: height / 4.3
            }
            : {
                x: width / (isDesktopOrLaptop ? 3 : 2.5),
                y: height / 2.5
            };
        var svg = baseSVG
            .append('g')
            //.attr("transform", "translate(" + width / 3 + "," + height / 2.5 + ")");
            .attr('transform', "translate(" + pieConfig.x + ", " + pieConfig.y + ")");
        var g = svg.selectAll('.arc').data(pie(data)).enter().append('g').attr('class', 'arc');
        g.append('path')
            .attr('d', arc)
            .attr('fill', function (d) { return color(d.data.label); })
            .transition()
            .duration(400)
            .attrTween('d', tweenPie);
        g.on('mouseover', function (d, i, elements) {
            tooltip.transition().duration(200).style('opacity', 0.7);
            tooltip
                .html("<div class=\"tooltip-inner\">" + d.data.label + " " + toThousands(locale, d.data.value) + "</div>")
                .style('left', d3.event.pageX + 'px')
                .style('top', d3.event.pageY - 28 + 'px');
            d3.selectAll(elements).filter(':not(:hover)').style('fill-opacity', 0.6);
            d3.selectAll(elements).filter(':hover').style('cursor', 'pointer');
            d3.select(elements[i]).transition().duration(100).attr('d', arcOver);
        })
            .on('mousemove', function (d) {
            tooltip.style('left', d3.event.pageX + 'px').style('top', d3.event.pageY - 28 + 'px');
        })
            .on('mouseout', function (d, i, elements) {
            tooltip.transition().duration(500).style('opacity', 0);
            d3.selectAll(elements).style('fill-opacity', 1);
            d3.select(elements[i]).transition().duration(100).attr('d', arc);
        })
            .on('click', function (d, i, el) {
            onClick(d.data.label);
        });
        //`translate(${direction === "column" ? "0, 100" : "(360, 0)"})`
        //(260, 15)
        //(15, 350)
        var labelConfig = direction === 'column'
            ? {
                x: 0,
                y: 320
            }
            : {
                x: isDesktopOrLaptop ? width - 280 : 160,
                y: isDesktopOrLaptop ? 15 : 350
            };
        baseSVG
            .append('g')
            .attr('transform', "translate(" + (labelConfig.x + 35) + ", " + labelConfig.y + ")")
            .selectAll('.text')
            .data(data)
            .enter()
            .append('text')
            .attr('class', 'text')
            .attr('fill', theme.fontColor)
            .call(getYAxis)
            .text(function (d) { return "" + d.label; })
            .on('click', function (d) {
            onClick(d);
        });
        baseSVG
            .append('g')
            .attr('transform', "translate(" + (labelConfig.x + 220) + ", " + labelConfig.y + ")")
            .selectAll('.text')
            .data(data)
            .enter()
            .append('text')
            .attr('class', 'text')
            .attr('fill', theme.fontColor)
            .call(getYAxis)
            .text(function (d) { return "" + toThousands(locale, d.value); });
        baseSVG
            .append('g')
            .attr('transform', "translate(" + (labelConfig.x + 20) + ", " + (labelConfig.y - 10) + ")")
            .selectAll('.label')
            .data(data)
            .enter()
            .append('rect')
            .attr('class', 'label')
            .style('fill', function (d) { return color(d.label); })
            .call(getYAxis)
            .attr('width', 10)
            .attr('height', 10);
    }, [d3Container.current, data]);
    return (React.createElement(Pie, null,
        React.createElement(Logo, { src: theme.type === 'dark' ? './KIDO_squared_white.png' : './kido-squared-black.png' }),
        React.createElement(PieMetadata, { theme: theme }, metadata[0] &&
            metadata.map(function (m, i) { return (React.createElement(Statistic, { key: id + "_i", title: m.label, value: toThousands(locale, m.value), prefix: React.createElement(Icon, { type: "usergroup-delete" }) })); })),
        React.createElement(PieChild, null, children),
        React.createElement("div", { ref: d3Container, id: id, className: "pie", style: style })));
};
export default PieCmp;
