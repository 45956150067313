export function getFeatureById(layer, idSearched, idProperty) {
    if (idProperty === void 0) { idProperty = null; }
    var outFeature = null;
    idProperty = (idProperty) ? idProperty : layer._geometryIDReferenceProperty;
    layer
        .getMapLayerObject()
        .getSource()
        .forEachFeature(function (feature) {
        var ft = feature.get(idProperty);
        if (ft == idSearched) {
            outFeature = feature;
        }
    });
    return outFeature;
}
