export default {
  spain: {
    user: "kido",
    pass: "kido"
  },
  "kuwait-districts": {
    user: "kido",
    pass: "kido"
  },
  "brazil-parana-parana": {
    user: "kidobr",
    pass: "pr04mm"
  },
  "brazil-parana-curitiba": {
    user: "kidobr",
    pass: "pr04mm"
  },
  "brazil-ceara-ceara": {
    user: "kidobr",
    pass: "pr04mm"
  },
  "brazil-ceara-fortaleza": {
    user: "kidobr",
    pass: "pr04mm"
  }
};
