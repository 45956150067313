import React, { Fragment, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Icon, Switch, Tooltip, ConfigProvider } from "antd";
import esES from "antd/es/locale/es_ES";
import locale from "antd/es/date-picker/locale/es_ES";
import moment from "moment";
import { Context } from "../../store";
import { Filters, Dates, Msg, Reset, Flip } from "./Filters.styles";
import Provinces from "../Provinces";
import theme from "../../theme";

const dateFormat = "YYYY-MM-DD";
const firstDateEnabled = moment("2020-02-01", dateFormat);

const MsgEn = () => (
  <Fragment>
    For more detailed statistics visit{" "}
    <a target="_blank" href="http://odmatrix.app">
      odmatrix.app
    </a>{" "}
    or contact <a href="mailto:info@kidodynamics.com">info@kidodynamics.com</a>
  </Fragment>
);

const MsgEs = () => (
  <Fragment>
    Para estadísticas más detalladas visite{" "}
    <a target="_blank" href="http://odmatrix.app">
      odmatrix.app
    </a>{" "}
    o contacte <a href="mailto:info@kidodynamics.com">info@kidodynamics.com</a>
  </Fragment>
);

const FiltersCmp = (props) => {
  const { t, i18n } = useTranslation();
  const [state, dispatch]: any = useContext(Context);

  const onDateChange = (value) => {
    console.log("hola");

    dispatch({
      type: "SET_DATE",
      payload: {
        startDate: value[0].format(dateFormat),
        endDate: value[1].format(dateFormat)
      }
    });
  };

  const disabledDate = (current) => {
    return current < firstDateEnabled || current > moment().subtract(1, "day");
  };

  const onResetDestination = () => {
    dispatch({
      type: "SET_DESTINATION",
      payload: "all"
    });

    if (state.map) {
      let prov_layer = state.map
        .getViewStateManager()
        .getKidoLayerByIDInView("main_od_layer", "main_od_view");
      let prov_view = state.map
        .getViewStateManager()
        .getViewByName("main_od_view");
      state.map
        .getViewStateManager()
        .selectFeatureLayerOnView(prov_view, prov_layer, state.destination, {
          event: "right_click"
        });
    }
  };

  const onResetOrigin = () => {
    dispatch({
      type: "SET_ORIGIN",
      payload: "all"
    });

    if (state.map) {
      let prov_layer = state.map
        .getViewStateManager()
        .getKidoLayerByIDInView("main_od_layer", "main_od_view");
      let prov_view = state.map
        .getViewStateManager()
        .getViewByName("main_od_view");
      state.map
        .getViewStateManager()
        .selectFeatureLayerOnView(prov_view, prov_layer, state.origin, {
          event: "left_click"
        });
    }
  };

  const onTripChange = (value) => {
    dispatch({
      type: "SET_MICROTRIPS",
      payload: value
    });
  };

  const onFlip = () => {
    dispatch({
      type: "SET_FLIP"
    });

    if (state.map) {
      let prov_layer = state.map
        .getViewStateManager()
        .getKidoLayerByIDInView("main_od_layer", "main_od_view");
      let prov_view = state.map
        .getViewStateManager()
        .getViewByName("main_od_view");
      state.map
        .getViewStateManager()
        .selectFeatureLayerOnView(prov_view, prov_layer, state.destination, {
          event: "left_click"
        });
      state.map
        .getViewStateManager()
        .selectFeatureLayerOnView(prov_view, prov_layer, state.origin, {
          event: "right_click"
        });
    }
  };

  console.log(state);

  return (
    <Filters open>
      <Row>
        {state.origin !== "all" && state.destination !== "all" && (
          <Tooltip placement="topLeft" title={t("flip")} arrowPointAtCenter>
            <Flip onClick={onFlip}>
              <Icon
                type="sync"
                style={{
                  color: theme.fontColor,
                  fontSize: "25px"
                }}
              />
            </Flip>
          </Tooltip>
        )}
        <Col span={5}>{t("origin")}</Col>
        <Col span={11}>
          <Provinces type="origin" />
        </Col>
        {state.origin !== "all" && (
          <Col span={2}>
            <Tooltip
              placement="topLeft"
              title={t("reset origin")}
              arrowPointAtCenter
            >
              <Reset onClick={onResetOrigin}>
                <Icon
                  type="close-circle"
                  style={{
                    color: theme.fontColor,
                    fontSize: "25px"
                  }}
                />
              </Reset>
            </Tooltip>
          </Col>
        )}
      </Row>
      <Row>
        <Col span={5}>{t("destination")}</Col>
        <Col span={11}>
          <Provinces type="destination" />
        </Col>
        {state.destination !== "all" && (
          <Col span={2}>
            <Tooltip
              placement="topLeft"
              title={t("reset destination")}
              arrowPointAtCenter
            >
              <Reset onClick={onResetDestination}>
                <Icon
                  type="close-circle"
                  style={{
                    color: theme.fontColor,
                    fontSize: "25px"
                  }}
                />
              </Reset>
            </Tooltip>
          </Col>
        )}
      </Row>
      <Row>
        <Col span={5}>{t("date")}</Col>
        <Col span={19}>
          <ConfigProvider locale={esES}>
            <Dates
              locale={locale}
              defaultValue={[
                moment(state.configDates[0], dateFormat),
                moment(state.configDates[1], dateFormat)
              ]}
              onChange={onDateChange}
              disabledDate={disabledDate}
              format={theme.dateFormat}
            />
          </ConfigProvider>
        </Col>
      </Row>
      {state.origin === 1 && state.destination === 1 && (
        <Row>
          <Col span={5}>{t("microtrips")}</Col>
          <Col span={19}>
            <Switch onChange={onTripChange} />
          </Col>
        </Row>
      )}
      {/*       <Row>
        <Msg>{i18n.language !== "es" ? <MsgEn /> : <MsgEs />}</Msg>
      </Row> */}
    </Filters>
  );
};

export default FiltersCmp;
