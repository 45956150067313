var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
export var Pie = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  height: 100%;\n  position: relative;\n"], ["\n  width: 100%;\n  height: 100%;\n  position: relative;\n"])));
export var PieMetadata = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  margin-bottom: 30px;\n  margin-left: 35px;\n  color: ", ";\n  font-weight: bolder;\n\n  .ant-statistic {\n    margin-right: 30px !important;\n  }\n"], ["\n  width: 100%;\n  margin-bottom: 30px;\n  margin-left: 35px;\n  color: ", ";\n  font-weight: bolder;\n\n  .ant-statistic {\n    margin-right: 30px !important;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.fontColor;
});
export var Logo = styled.img(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  top: 8px;\n  left: 0;\n  height: 25px;\n  position: absolute;\n"], ["\n  top: 8px;\n  left: 0;\n  height: 25px;\n  position: absolute;\n"])));
export var PieChild = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-bottom: 30px;\n"], ["\n  margin-bottom: 30px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
