import React, { useState } from "react";
import { Icon } from "antd";
import { useTranslation } from "react-i18next";
import { Helper, Close } from "./Helper.styles";

const HelperCmp = (props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(true);

  return (
    open && (
      <Helper onClick={() => setOpen(!open)}>
        <Close>
          <Icon
            type="close-circle"
            style={{
              color: "white",
              fontSize: "18px",
            }}
          />
        </Close>
        {t("help")}
      </Helper>
    )
  );
};

export default HelperCmp;
