import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ProvinceSelect } from "./Provinces.styles";
import { Context } from "../../store";
import alpha from "../../data/alpha";

const Provinces = ({ type }) => {
  const [state, dispatch]: any = useContext(Context);
  const { t } = useTranslation();
  const sortAlpha = alpha("name");

  const onProvinceChange = (payload) => {
    dispatch({
      type: type === "origin" ? "SET_ORIGIN" : "SET_DESTINATION",
      payload,
    });

    if (state.map) {
      let prov_layer = state.map
        .getViewStateManager()
        .getKidoLayerByIDInView("main_od_layer", "main_od_view");
      let prov_view = state.map
        .getViewStateManager()
        .getViewByName("main_od_view");
      state.map
        .getViewStateManager()
        .selectFeatureLayerOnView(prov_view, prov_layer, payload, {
          event: type === "origin" ? "left_click" : "right_click",
        });
    }
  };

  const base = {
    style: { width: "300px", height: "35px" },
    placeholder: `${t("Select a " + type)}`,
    onChange: onProvinceChange,
  };

  const defaultValue =
    state[type] && state[type] !== "all"
      ? { value: state[type] }
      : { value: undefined };

  const config = {
    ...base,
    ...defaultValue,
  };

  return (
    <ProvinceSelect
      {...config}
      showSearch
      filterOption={(input, option: any) =>
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {state.provinces.sort(sortAlpha).map((prov) => (
        <ProvinceSelect.Option
          value={parseInt(prov.id)}
          key={parseInt(prov.id)}
        >
          {prov.name}
        </ProvinceSelect.Option>
      ))}
    </ProvinceSelect>
  );
};

export default Provinces;
