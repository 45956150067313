import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Landing, LandingLogo, LandingMenu, LandingLogoHolder, LandingMsg, Holder } from './Landing.styles';
import Auth from '../Auth';

const LandingCmp = ({ auth }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [modal, setModal] = useState({ trigger: null, open: false });

  const onOpenModal = (trigger) => () => setModal({ trigger, open: true });

  return (
    <Holder>
      <Landing>
        <LandingLogoHolder>
          <LandingLogo src="./KIDO_horizontal_white.png" />
          <LandingMsg>Mobility</LandingMsg>
        </LandingLogoHolder>
        <LandingMenu>
          <button onClick={onOpenModal('spain')} className="landingMenuItem spainLogo">
            <span>{t('spain')}</span>
          </button>
          <button onClick={onOpenModal('kuwait-districts')} className="landingMenuItem kuwaitLogo">
            <span>Kuwait</span>
          </button>
          <button onClick={onOpenModal('brazil-parana-parana')} className="landingMenuItem brazilLogo">
            <span>Paraná</span>
          </button>
          <button onClick={onOpenModal('brazil-parana-curitiba')} className="landingMenuItem brazilLogo">
            <span>Curitiba</span>
          </button>
          <button onClick={onOpenModal('brazil-ceara-ceara')} className="landingMenuItem brazilLogo">
            <span>Ceará</span>
          </button>
          <button onClick={onOpenModal('brazil-ceara-fortaleza')} className="landingMenuItem brazilLogo">
            <span>Fortaleza</span>
          </button>
        </LandingMenu>
        <Auth login={auth} modal={modal} onClose={() => setModal({ trigger: null, open: false })} />
      </Landing>
    </Holder>
  );
};

export default LandingCmp;
