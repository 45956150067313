import { Group as LayerGroup } from "ol/layer.js";
import { ClorophletLayer, AbstractInfoLayer } from "../layers/layers";
import { LiteEvent } from "../events";
var MapViewState = /** @class */ (function () {
    function MapViewState(name, visible, geometryLabel, layers) {
        if (layers === void 0) { layers = []; }
        this.layers = [];
        this.informationalLayers = [];
        // A view can hold a view child,
        // wich is activated when a certain zoom level on the map is reached
        this.childViewState = null;
        this.parentViewState = null;
        this.childZoomTreshold = null;
        this.kidoLayerSelected = null; // holds a reference of the last layer selected in the view
        this.featureSelected = null; // holds reference of the last feature selected in the view
        this.allFeaturesSelected = []; // list with all the features selected
        this.name = name;
        this.layers = layers;
        this.geometryLabel = geometryLabel;
        this.layerGroup = new LayerGroup({
            layers: this.getLayersObjects(),
            visible: visible,
            name: name
        });
        this.viewEventHandler = new ViewEvent();
    }
    MapViewState.prototype.addLayer = function (layer) {
        this.layers.push(layer);
        //this.layerGroup.getLayers().push(layer);
    };
    MapViewState.prototype.addChildView = function (child, childZoomTreshold) {
        this.childViewState = child;
        this.childZoomTreshold = childZoomTreshold;
        this.childViewState.parentViewState = this;
    };
    MapViewState.prototype.contructViewParentFeatureName = function () {
        var parent_identifier_string = "";
        if (this.parentViewState !== null) {
            parent_identifier_string +=
                this.parentViewState
                    .getTransferLayer()
                    .getFeatureSelectedIdentifierPropertyValue() + "_";
        }
        return parent_identifier_string;
    };
    MapViewState.prototype.updateLayersBasedOnParentView = function (updateLayersSource) {
        var that = this;
        for (var i in this.layers) {
            // 1st: update layer source names
            var full_parent_name = this.contructViewParentFeatureName();
            if (this.layers[i].getNameDependsOnParent()) {
                this.layers[i].setParentFeatureSelectedName(full_parent_name);
            }
            // 2nd: update layers cql filters
            if (this.layers[i].getConnectParentWithCQLFilters()) {
                // reset previous CQL filters, and add the new one
                this.layers[i].addCQLFilter(this.parentViewState
                    .getTransferLayer()
                    ._geometryIDReferenceProperty, this.parentViewState
                    .getTransferLayer()
                    .getFeatureSelectedIdentifierPropertyValue(), true);
            }
            if (updateLayersSource) {
                if (this.layers[i] === this.getBoundaryReferenceLayer()) {
                    this.layers[i].updateLayerSource();
                    /*this.layers[i].getMapLayerObject().getSource().once('change', function(e) {
                      
                        //that.viewEventHandler.OnBoundaryLayerUpdated.trigger();
                    })*/
                }
            }
        }
    };
    MapViewState.prototype.getLayersObjects = function () {
        var layersObjects = [];
        for (var i in this.layers) {
            layersObjects.push(this.layers[i].getMapLayerObject());
            if (this.layers[i] instanceof ClorophletLayer ||
                this.layers[i] instanceof AbstractInfoLayer) {
                this.informationalLayers.push(this.layers[i]);
            }
        }
        return layersObjects;
    };
    MapViewState.prototype.enterView = function () {
        this.getLayersGroup().setVisible(true);
    };
    // Call this when the parent view enters this child
    MapViewState.prototype.enterViewFromParent = function () {
        this.updateLayersBasedOnParentView(true);
        this.enterView();
    };
    MapViewState.prototype.resetLayersObjectsState = function () {
        for (var i in this.layers) {
            this.layers[i].resetLayerState();
        }
    };
    MapViewState.prototype.getLayersGroup = function () {
        return this.layerGroup;
    };
    MapViewState.prototype.getName = function () {
        return this.name;
    };
    MapViewState.prototype.getChildViewState = function () {
        return this.childViewState;
    };
    MapViewState.prototype.getParentViewState = function () {
        return this.parentViewState;
    };
    MapViewState.prototype.getGeometryLabel = function () {
        return this.geometryLabel;
    };
    MapViewState.prototype.setBoundaryReferenceLayer = function (layer) {
        this.boundaryReferenceLayer = layer;
        // every view has to trigger an event when its boundary layer source is updated
        var that = this;
        layer.getMapLayerObject().getSource().once('change', function (e) {
            that.viewEventHandler.OnBoundaryLayerUpdated.trigger();
        });
    };
    MapViewState.prototype.getBoundaryReferenceLayer = function () {
        return this.boundaryReferenceLayer;
    };
    MapViewState.prototype.setTransferLayer = function (layer) {
        this.transferLayer = layer;
    };
    MapViewState.prototype.getTransferLayer = function () {
        return this.transferLayer;
    };
    MapViewState.prototype.setLayerSelected = function (layer) {
        this.kidoLayerSelected = layer;
    };
    MapViewState.prototype.getLayerSelected = function () {
        return this.kidoLayerSelected;
    };
    MapViewState.prototype.setFeatureSelected = function (feature) {
        this.featureSelected = feature;
        for (var i in this.layers) {
            this.layers[i].setFeatureSelected(this.featureSelected);
        }
    };
    MapViewState.prototype.getFeatureSelected = function () {
        return this.featureSelected;
    };
    MapViewState.prototype.getAllFeaturesSelected = function () {
        return this.allFeaturesSelected;
    };
    MapViewState.prototype.setAllFeaturesSelected = function (featuresList) {
        this.allFeaturesSelected = featuresList;
    };
    MapViewState.prototype.resetAllFeaturesSelected = function () {
        this.allFeaturesSelected = [];
    };
    MapViewState.prototype.getInformationalLayers = function () {
        return this.informationalLayers;
    };
    return MapViewState;
}());
export { MapViewState };
var ViewEvent = /** @class */ (function () {
    function ViewEvent() {
        this.moveToChild = new LiteEvent();
        this.onMoveToParent = new LiteEvent();
        this.onEnter = new LiteEvent();
        this.onBoundaryLayerUpdated = new LiteEvent();
        // Call this when a feature on a non transfer layer is clicked
        this.onFeatureClicked = new LiteEvent();
    }
    Object.defineProperty(ViewEvent.prototype, "OnMoveToChild", {
        get: function () {
            return this.moveToChild.expose();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ViewEvent.prototype, "OnMoveToParent", {
        get: function () {
            return this.onMoveToParent.expose();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ViewEvent.prototype, "OnEnter", {
        get: function () {
            return this.onEnter.expose();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ViewEvent.prototype, "OnBoundaryLayerUpdated", {
        get: function () {
            return this.onBoundaryLayerUpdated.expose();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ViewEvent.prototype, "OnFeatureClicked", {
        get: function () {
            return this.onFeatureClicked.expose();
        },
        enumerable: true,
        configurable: true
    });
    return ViewEvent;
}());
export { ViewEvent };
