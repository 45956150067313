export default {
  projects: [
    {
      country: 'kuwait',
      id: 'kuwait_districts',
      name: 'Kuwait - Districts',
      modules: {
        od: {
          views: [
            {
              root_view: true,
              name: 'main_od_view',
              visible: true,
              geometry_label: 'Cell',
              layers: [
                {
                  type: 'vector',
                  id: 'main_od_layer',
                  name: 'macromobility_kuwait_districts',
                  title: 'Kuwait - Districts',
                  visible: true,
                  opacity: 0.3,
                  is_boundary_layer: true,
                  geom_reference_property: 'id',
                  label_reference_property: 'name',
                  select_behavior: 'origin_destination',
                  hover_behavior: 'tooltip_feature_label'
                }
              ]
            }
          ]
        }
      },
      map: {
        view: {
          projection: 'EPSG:3857',
          center: [5204903.004051, 3421015.637931],
          zoom: 9
        },
        enable_hover_behaviors: true
      }
    }
  ]
};
