import styled from "styled-components";
import { Select } from "antd";

export const SwitchHolder = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
`;

export const Switch = styled(Select)`
  width: 100px;

  .ant-select-selection-selected-value {
    text-transform: capitalize;
  }

  .ant-select-selection__placeholder {
    margin-top: -8px !important;
  }

  .ant-select-selection-selected-value {
    margin-top: 2px !important;
  }
`;
