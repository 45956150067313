import React, { useRef, useEffect } from "react";
import * as d3 from "d3";
import moment from "moment";
import { Histogram, HistogramMetadata, Logo } from "./Histogram.styles";
import { toThousands, isWeekend } from "../helper";
var HistogramCmp = function (_a) {
    var data = _a.data, title = _a.title, theme = _a.theme, locale = _a.locale, isDesktopOrLaptop = _a.isDesktopOrLaptop, style = _a.style;
    var d3Container = useRef(null);
    var margin = { top: 10, right: 10, bottom: 60, left: 50 };
    var width = style.width - margin.left - margin.right;
    var height = style.height - margin.top - margin.bottom;
    var max = d3.max(data.map(function (d) { return d.value; }));
    var tooltip = d3.select(".tooltip");
    useEffect(function () {
        d3.select(d3Container.current).select("svg").remove();
        var svg = d3
            .select(d3Container.current)
            .append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform", "translate(" + margin.left + ", " + margin.top + ")");
        var yScale = d3.scaleLinear().domain([0, max]).range([height, 0]);
        var yAxis = d3.axisLeft(yScale).ticks(20, "s");
        svg.call(yAxis).selectAll("text").style("fill", theme.fontColor);
        var xScale = d3
            .scaleBand()
            .padding(0.2)
            .domain(data.map(function (d) { return d.label; }))
            .range([0, width]);
        var xAxis = d3.axisBottom(xScale).ticks(5);
        svg
            .append("g")
            .attr("transform", "translate(0, " + height + ")")
            .call(xAxis)
            .selectAll("text")
            .style("text-anchor", "end")
            .text(function (d) { return moment(d).format("DD-MM-YYYY"); })
            //.text((d) => d)
            .attr("transform", "rotate(-45)")
            .style("font-size", "12px")
            .style("fill", theme.fontColor);
        svg
            .selectAll("rect")
            .data(data)
            .enter()
            .append("rect")
            .on("mouseover", function (d, i, elements) {
            tooltip.transition().duration(200).style("opacity", 0.7);
            tooltip
                .html("<div class=\"tooltip-inner\"><strong>" + toThousands(locale, d.value) + "</strong></div>")
                .style("left", d3.event.pageX + "px")
                .style("top", d3.event.pageY - 28 + "px");
            d3.selectAll(elements)
                .filter(":not(:hover)")
                .style("fill-opacity", 0.6);
        })
            .on("mousemove", function (d) {
            tooltip
                .style("left", d3.event.pageX + "px")
                .style("top", d3.event.pageY - 28 + "px");
        })
            .on("mouseout", function (d, i, elements) {
            tooltip.transition().duration(500).style("opacity", 0);
            d3.selectAll(elements).style("fill-opacity", 1);
        })
            .attr("x", function (d) { return xScale(d.label); })
            .attr("width", function (d) { return xScale.bandwidth(); })
            .attr("y", yScale(0))
            .attr("height", 0)
            .transition()
            .delay(100)
            .duration(800)
            .attr("y", function (d) { return yScale(d.value); })
            .attr("height", function (d) { return height - yScale(d.value); })
            .style("fill", function (d) {
            return isWeekend(d.label) ? theme.colors[2] : theme.colors[0];
        });
    }, [d3Container.current]);
    return (React.createElement(Histogram, null,
        React.createElement(Logo, { src: theme.type === "dark"
                ? "./KIDO_squared_white.png"
                : "./kido-squared-black.png" }),
        React.createElement(HistogramMetadata, { theme: theme }, title),
        React.createElement("div", { ref: d3Container, className: "histogram", style: style })));
};
export default HistogramCmp;
