export default {
  projects: [
    {
      country: "spain",
      id: "provinces",
      name: "Spain provinces",
      modules: {
        od: {
          views: [
            {
              root_view: true,
              name: "main_od_view",
              visible: true,
              geometry_label: "Province",
              layers: [
                {
                  type: "vector",
                  id: "main_od_layer",
                  name: "tourismapp_spain_all_provinces",
                  title: "Spain provinces",
                  visible: true,
                  opacity: 0.3,
                  is_boundary_layer: true,
                  geom_reference_property: "id_prov",
                  label_reference_property: "name",
                  select_behavior: "origin_destination"
                }
              ]
            }
          ]
        }
      },
      map: {
        view: {
          projection: "EPSG:3857",
          center: [-777823.19983, 4879739.885726],
          zoom: 7
        }
      }
    },
    {
      country: "brazil",
      id: "provinces",
      name: "Brazil provinces",
      modules: {
        od: {
          views: [
            {
              root_view: true,
              name: "main_od_view",
              visible: true,
              geometry_label: "Cell",
              layers: [
                {
                  type: "vector",
                  id: "main_od_layer",
                  name: "tourismapp_brazil_all_provinces",
                  title: "Brazil provinces",
                  visible: true,
                  opacity: 0.3,
                  is_boundary_layer: true,
                  geom_reference_property: "id",
                  label_reference_property: "name",
                  select_behavior: "origin_destination"
                }
              ]
            }
          ]
        }
      },
      map: {
        view: {
          projection: "EPSG:3857",
          center: [-7156951.832398, -1521402.610988],
          zoom: 5
        }
      }
    }
  ]
};
