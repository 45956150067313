import styled from 'styled-components';
import { Button } from 'antd';
import theme from '../../theme';

export const PanelWrapper: any = styled.div`
  width: 800px;
  height: 100%;
  padding: 15px;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  transform: ${({ open }: any) => (open ? 'translateX(0)' : 'translateX(-685px)')};
  transition: transform 0.2s ease-out;
  z-index: 99;
`;

export const PanelHolder = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 6px;
  /* background: white; */
  background: ${theme.background};
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px;
  display: flex;
  flex-direction: column;
`;

export const PanelToggleButton = styled(Button)`
  position: absolute;
  top: 0;
  right: -45px;
  background-color: rgb(106, 116, 133);
  color: rgb(41, 50, 60);

  &.ant-btn > .anticon {
    line-height: 1.3;
  }
`;

export const PanelHeader = styled.div`
  padding: 25px 25px 0 25px;
  flex: 0 1 60px;
  box-sizing: border-box;
  background: ${theme.background};
  border-radius: 6px;
`;

export const PanelContent = styled.div`
  flex: 1;
  padding: 25px;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hdden;
`;

export const PanelLogo = styled.img`
  height: 20px;
  cursor: pointer;
`;
