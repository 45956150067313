import Map from "ol/Map.js";
import View from "ol/View.js";
import { defaults as defaultControls, ScaleLine } from "ol/control.js";
import TileLayer from "ol/layer/Tile.js";
import XYZ from "ol/source/XYZ.js";
import { Group as LayerGroup } from "ol/layer.js";
import Overlay from 'ol/Overlay';
var KidoMap = /** @class */ (function () {
    function KidoMap(mapOptions) {
        this.voronoiID = null;
        this.featureFilters = {};
        this._clickHitTolerance = 5;
        this.selectInteraction = null;
        this.itsOnShowcaseMode = false;
        this.mapOptions = {};
        this.enabledHoverBehaviors = false;
        this.mapOptions = mapOptions || this.getDefaultMapOptions();
    }
    KidoMap.prototype.setHandler = function (handler) {
        this.handler = handler;
    };
    KidoMap.prototype.getHandler = function () {
        return this.handler;
    };
    KidoMap.prototype.initMap = function (target, theme) {
        if (theme === void 0) { theme = 'light'; }
        this.target = target;
        this.baseLayersGroup = new LayerGroup({
            layers: [
                new TileLayer({
                    source: new XYZ({
                        url: "http://{1-4}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
                    }),
                    name: "light_base_layer",
                    visible: theme == 'light'
                }),
                new TileLayer({
                    source: new XYZ({
                        url: "http://{1-4}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png"
                    }),
                    name: "dark_base_layer",
                    visible: theme == 'dark'
                })
            ],
            visible: true,
            name: "base_layers_group",
            title: "Base layers"
        });
        var that = this;
        this.mapObject = new Map({
            controls: defaultControls().extend([new ScaleLine({})]),
            layers: [this.baseLayersGroup],
            target: this.target,
            view: new View({
                projection: that.mapOptions.view.projection,
                center: that.mapOptions.view.center,
                zoom: that.mapOptions.view.zoom
            })
        });
        this.loadViewStateLayers();
        this.getViewStateManager().listenUserInteractions();
        this.activateFeatureHoverEvent();
        this.createUserTooltips();
    };
    KidoMap.prototype.getDefaultMapOptions = function () {
        return {
            view: {
                projection: "EPSG:4326",
                center: [0.002745, 40.418997],
                zoom: 7
            }
        };
    };
    KidoMap.prototype.resetMapState = function () {
        this.itsOnShowcaseMode = false;
    };
    KidoMap.prototype.loadViewStateLayers = function () {
        for (var i in this.viewStateManager.viewStatesList) {
            this.mapObject.addLayer(this.viewStateManager.viewStatesList[i].getLayersGroup());
        }
    };
    KidoMap.prototype.getCQLFeaturesFilters = function () {
        var str = [];
        for (var p in this.featureFilters)
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(this.featureFilters[p]));
        return str.join("&");
    };
    KidoMap.prototype.updateInfoGroupLayer = function (layersGroup) {
        var that = this;
        layersGroup.getLayers().forEach(function (layer, index, a) {
            var targetVoronoiText = "target_feature:" + that.getFeatureIDSelected();
            that.updateInfoLayerParams(layer, targetVoronoiText);
        });
    };
    KidoMap.prototype.updateInfoLayerParams = function (layer, params) {
        layer.getSource().updateParams({ viewparams: params });
    };
    KidoMap.prototype.updateMunisClorophletLayerValues = function () {
        //this.handler.updateMunisClorophletLayerValues();
    };
    KidoMap.prototype.activateFeatureHoverEvent = function () {
        var that = this;
        this.mapObject.on("pointermove", function (e) {
            var pixel = that.mapObject.getEventPixel(e.originalEvent);
            var hit = that.mapObject.hasFeatureAtPixel(pixel, {
                hitTolerance: that.clickHitTolerance
            });
            that.mapObject.getViewport().style.cursor = hit ? "pointer" : "";
        });
    };
    KidoMap.prototype.setViewStateManager = function (viewmanager) {
        this.viewStateManager = viewmanager;
    };
    KidoMap.prototype.getViewStateManager = function () {
        return this.viewStateManager;
    };
    KidoMap.prototype.setFeatureSelected = function (feature) {
        this.featureSelected = feature;
    };
    KidoMap.prototype.getFeatureSelected = function () {
        return this.featureSelected;
    };
    KidoMap.prototype.setFeatureIDSelected = function (featureID) {
        this.featureIDSelected = featureID;
    };
    KidoMap.prototype.getFeatureIDSelected = function () {
        return this.getFeatureSelected().get(this.getStatLayerSelected()._geometryIDReferenceProperty);
    };
    KidoMap.prototype.setStatLayerSelected = function (statLayer) {
        this.statLayerSelected = statLayer;
    };
    KidoMap.prototype.getStatLayerSelected = function () {
        return this.statLayerSelected;
    };
    KidoMap.prototype.getClorophletLayersGroup = function () {
        return this.clorophletLayersGroup;
    };
    KidoMap.prototype.getBaseLayersGroup = function () {
        return this.baseLayersGroup;
    };
    KidoMap.prototype.updateFilterValue = function (key, value) {
        this.featureFilters[key] = value;
    };
    KidoMap.prototype.setFeatureFilters = function (filters) {
        this.featureFilters = filters;
    };
    KidoMap.prototype.getFeatureFilters = function () {
        return this.featureFilters;
    };
    Object.defineProperty(KidoMap.prototype, "clickHitTolerance", {
        get: function () {
            return this._clickHitTolerance;
        },
        set: function (value) {
            this._clickHitTolerance = value;
        },
        enumerable: true,
        configurable: true
    });
    KidoMap.prototype.getSelectInteraction = function () {
        return this.selectInteraction;
    };
    KidoMap.prototype.setAppEnvironment = function (environment) {
        this.appEnvironment = environment;
    };
    KidoMap.prototype.getAppEnvironment = function () {
        return this.appEnvironment;
    };
    KidoMap.prototype.getEnabledHoverBehaviors = function () {
        return this.enabledHoverBehaviors;
    };
    KidoMap.prototype.setEnabledHoverBehaviors = function (enabled) {
        this.enabledHoverBehaviors = enabled;
    };
    KidoMap.prototype.createUserTooltips = function () {
        if (this.mapOptions.enable_hover_behaviors) {
            this.createHoverBehaviorTooltip();
        }
    };
    KidoMap.prototype.createHoverBehaviorTooltip = function () {
        if (this.tooltipHoverBehaviorElement) {
            this.tooltipHoverBehaviorElement.parentNode.removeChild(this.tooltipHoverBehaviorElement);
        }
        this.tooltipHoverBehaviorElement = document.createElement('div');
        this.tooltipHoverBehaviorElement.className = 'ol-tooltip ol-tooltip-layer-hover hidden';
        this.tooltipHoverBehaviorOverlay = new Overlay({
            element: this.tooltipHoverBehaviorElement,
            offset: [0, -15],
            positioning: 'bottom-center'
        });
        this.mapObject.addOverlay(this.tooltipHoverBehaviorOverlay);
    };
    KidoMap.prototype.getTooltipHoverBehaviorElement = function () {
        return this.tooltipHoverBehaviorElement;
    };
    KidoMap.prototype.getTooltipHoverBehaviorOverlay = function () {
        return this.tooltipHoverBehaviorOverlay;
    };
    KidoMap.prototype.hideTooltipHoverBehavior = function () {
        this.getTooltipHoverBehaviorElement().classList.add('hidden');
        this.tooltipHoverBehaviorOverlay.setPosition(undefined);
    };
    return KidoMap;
}());
export { KidoMap };
