export default {
  origin: "Origin",
  destination: "Destination",
  date: "Date",
  microtrips: "Microtrips",
  selectOrigin:
    "Smart data for smarter destinations: select an origin to start",
  "Select a destination": "Select a destination",
  "Select a origin": "Select an origin",
  orDestination: "or destination",
  "Total trips": "Total trips",
  "In/Out trips": "In/Out trips",
  "In/In trips": "In/In trips",
  moreStats: "Para más estadísticas visite",
  spain: "Spain",
  username: "Username",
  password: "Password",
  login: "Login",
  cancel: "Cancel",
  ok: "OK",
  help: "Left click to select an origin, right click to select a destination",
  "reset origin": "Remove origin",
  "reset destination": "Remove destination",
  flip: "Switch",
  accept: "OK",
  "info btn": "Technical note and methodology",
};
