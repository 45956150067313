import axios from "axios";

const base = "https://admin.macromobility.app/api";
const od_module = "od"
const visits_module = "visits"

const request = () => (url) =>
  axios({
    url,
    method:
      "get" /* ,
    headers: {
      "x-api-key": "2RlxfwkZ9h6TCVaFgpCbG24a3YNGuh1D4wsGJmrK"
    } */
  });

const r = request();

const getMicrotrips = (project, microtrips, origin, destination) => {
  return project !== "provinces" && origin === 1 && destination === 1
    ? `microtrips=${microtrips}`
    : "";
};

export const getTrips = (config) => {
  const {
    origin,
    destination,
    startDate,
    endDate,
    country,
    project,
    microtrips
  } = config;

  return r(
    `${base}/${od_module}/total_trips/${country}/${project}/${origin}/${destination}/${startDate}/${endDate}?${getMicrotrips(
      project,
      microtrips,
      origin,
      destination
    )}`
  );
};

export const getTripsPerDestination = (config) => {
  const {
    origin,
    startDate,
    endDate,
    country,
    project,
    microtrips,
    destination
  } = config;

  return r(
    `${base}/${od_module}/trips_perdestination/${country}/${project}/${origin}/${startDate}/${endDate}?exclude_diag_from_result=true&${getMicrotrips(
      project,
      microtrips,
      origin,
      destination
    )}`
  );
};

export const getTripsPerDay = (config) => {
  const {
    origin,
    destination,
    startDate,
    endDate,
    country,
    project,
    microtrips
  } = config;

  return r(
    `${base}/${od_module}/total_trips_perday/${country}/${project}/${origin}/${destination}/${startDate}/${endDate}?${getMicrotrips(
      project,
      microtrips,
      origin,
      destination
    )}`
  );
};

export const getTripsPerOrigin = (config) => {
  const {
    destination,
    startDate,
    endDate,
    country,
    project,
    microtrips,
    origin
  } = config;

  return r(
    `${base}/${od_module}/trips_perorigin/${country}/${project}/${destination}/${startDate}/${endDate}?exclude_diag_from_result=true&${getMicrotrips(
      project,
      microtrips,
      origin,
      destination
    )}`
  );
};

export const getTripsPerHour = (config) => {
  const {
    origin,
    startDate,
    destination,
    endDate,
    country,
    project,
    microtrips
  } = config;

  return r(
    `${base}/${od_module}/trips_byhour/${country}/${project}/${origin}/${destination}/${startDate}/${endDate}?${getMicrotrips(
      project,
      microtrips,
      origin,
      destination
    )}`
  );
};

export const getVisitsByResidence = (config) => {
  const { origin, startDate, destination, endDate, country, project } = config;

  return r(
    `${base}/${visits_module}/visits_byresidence/${country}/${project}/${origin}/${destination}/${startDate}/${endDate}`
  );
};

export const getProvinces = () =>
  r(
    `https://h0ttyb23kk.execute-api.eu-central-1.amazonaws.com/visits/provinces`
  );
