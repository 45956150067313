import styled from "styled-components";

export const Helper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 6px;
  padding: 10px;
  cursor: pointer;
`;

export const Close = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  margin-right: 3px;
`;
