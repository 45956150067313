var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var AbstractSelectBehavior = /** @class */ (function () {
    function AbstractSelectBehavior(referenceLayer) {
        this.referenceLayer = referenceLayer;
        this.referenceLayer.setSelectableBehavior(this);
    }
    return AbstractSelectBehavior;
}());
export { AbstractSelectBehavior };
var NullSelectBehavior = /** @class */ (function (_super) {
    __extends(NullSelectBehavior, _super);
    function NullSelectBehavior(referenceLayer) {
        return _super.call(this, referenceLayer) || this;
    }
    NullSelectBehavior.prototype.onSelectAction = function (feature) {
        throw new Error("Method not implemented.");
    };
    NullSelectBehavior.prototype.isSelectable = function () {
        return false;
    };
    return NullSelectBehavior;
}(AbstractSelectBehavior));
export { NullSelectBehavior };
var SimpleSelectBehavior = /** @class */ (function (_super) {
    __extends(SimpleSelectBehavior, _super);
    function SimpleSelectBehavior(referenceLayer) {
        var _this = _super.call(this, referenceLayer) || this;
        _this.featuresSelected = [];
        return _this;
    }
    SimpleSelectBehavior.prototype.onSelectAction = function (view, feature, options) {
        this.updateFeaturesSelectedStatus(undefined);
        this.featuresSelected = [];
        if (feature == undefined && options["select_all_on_null"]) {
            this.featuresSelected = this.referenceLayer.getMapLayerObject().getSource().getFeatures();
        }
        else {
            this.featuresSelected.push(feature);
        }
        this.updateFeaturesSelectedStatus(true);
    };
    SimpleSelectBehavior.prototype.updateFeaturesSelectedStatus = function (status) {
        this.featuresSelected.forEach(function (feature) {
            feature.set("origin_selected", status);
        });
    };
    SimpleSelectBehavior.prototype.isSelectable = function () {
        return true;
    };
    return SimpleSelectBehavior;
}(AbstractSelectBehavior));
export { SimpleSelectBehavior };
var MultipleSelectBehavior = /** @class */ (function (_super) {
    __extends(MultipleSelectBehavior, _super);
    function MultipleSelectBehavior(referenceLayer) {
        var _this = _super.call(this, referenceLayer) || this;
        _this.lastSelectedEventType = null;
        _this.featuresSelected = [];
        return _this;
    }
    MultipleSelectBehavior.prototype.onSelectAction = function (view, feature, options) {
        if (feature == undefined && options["select_all_on_null"]) {
            this.resetFeatures();
            this.featuresSelected = [];
        }
        else {
            var indexF = this.featuresSelected.indexOf(feature);
            if (options["event"] == "left_click") {
                if (indexF == -1) {
                    this.featuresSelected.push(feature);
                    feature.set("origin_selected", true);
                    this.lastSelectedEventType = 'select';
                }
            }
            else if (options["event"] == "right_click") {
                if (indexF != -1) {
                    this.featuresSelected.splice(indexF, 1);
                    feature.set("origin_selected", false);
                    this.lastSelectedEventType = 'deselect';
                }
            }
        }
    };
    MultipleSelectBehavior.prototype.resetFeatures = function () {
        this.featuresSelected.forEach(function (feature) {
            feature.set("origin_selected", null);
        });
    };
    MultipleSelectBehavior.prototype.getLastSelectedEventType = function () {
        return this.lastSelectedEventType;
    };
    MultipleSelectBehavior.prototype.isSelectable = function () {
        return true;
    };
    return MultipleSelectBehavior;
}(AbstractSelectBehavior));
export { MultipleSelectBehavior };
var OriginDestinationSelectBehavior = /** @class */ (function (_super) {
    __extends(OriginDestinationSelectBehavior, _super);
    function OriginDestinationSelectBehavior(referenceLayer) {
        var _this = _super.call(this, referenceLayer) || this;
        _this.lastSelectedEventType = null;
        _this.lastSelectedPolygonType = "origin";
        _this.featuresSelected = {
            origin: null,
            destination: null
        };
        return _this;
    }
    OriginDestinationSelectBehavior.prototype.onSelectAction = function (view, feature, options) {
        var targetType;
        // set targetType
        if (options["event"]) {
            if (options["event"] == "left_click") {
                targetType = "origin";
            }
            else if (options["event"] == "right_click") {
                targetType = "destination";
            }
        }
        else {
            targetType =
                this.featuresSelected.origin == null ? "origin" : "destination";
        }
        // select origin|destination & update view features selected
        if (targetType == "origin") {
            this.selectOrigin(feature);
        }
        else if (targetType == "destination") {
            this.selectDestination(feature);
        }
        var selectedFeatures = [];
        if (this.featuresSelected.origin !== null) {
            selectedFeatures.push(this.featuresSelected.origin);
        }
        if (this.featuresSelected.destination !== null) {
            selectedFeatures.push(this.featuresSelected.destination);
        }
        view.setAllFeaturesSelected(selectedFeatures);
    };
    OriginDestinationSelectBehavior.prototype.getFeatureID = function (feature) {
        return feature.get(this.referenceLayer.geometryIDReferenceProperty);
    };
    OriginDestinationSelectBehavior.prototype.selectOrigin = function (feature) {
        var deselectOrigin = this.featuresSelected.origin === feature;
        this.resetOrigin();
        if (!deselectOrigin) {
            this.featuresSelected.origin = feature;
            feature.set("origin_selected", true);
        }
        this.lastSelectedPolygonType = "origin";
        this.lastSelectedEventType = (deselectOrigin) ? "deselect" : "select";
    };
    OriginDestinationSelectBehavior.prototype.resetOrigin = function () {
        if (this.featuresSelected.origin) {
            this.featuresSelected.origin.set("origin_selected", null);
        }
        this.featuresSelected.origin = null;
    };
    OriginDestinationSelectBehavior.prototype.selectDestination = function (feature) {
        var deselectDestination = this.featuresSelected.destination === feature;
        this.resetDestination();
        if (!deselectDestination) {
            this.featuresSelected.destination = feature;
            feature.set("destination_selected", true);
        }
        this.lastSelectedPolygonType = "destination";
        this.lastSelectedEventType = (deselectDestination) ? "deselect" : "select";
    };
    OriginDestinationSelectBehavior.prototype.resetDestination = function () {
        if (this.featuresSelected.destination) {
            this.featuresSelected.destination.set("destination_selected", null);
        }
        this.featuresSelected.destination = null;
    };
    OriginDestinationSelectBehavior.prototype.getLastSelectedEventType = function () {
        return this.lastSelectedEventType;
    };
    OriginDestinationSelectBehavior.prototype.getLastSelectedPolygonType = function () {
        return this.lastSelectedPolygonType;
    };
    OriginDestinationSelectBehavior.prototype.getLastSelectedFeatureID = function () {
        return this.getFeatureID(this.featuresSelected[this.lastSelectedPolygonType]);
    };
    OriginDestinationSelectBehavior.prototype.isSelectable = function () {
        return true;
    };
    return OriginDestinationSelectBehavior;
}(AbstractSelectBehavior));
export { OriginDestinationSelectBehavior };
// FACTORY /////////////////////
var KidoSelectBehaviorFactory = /** @class */ (function () {
    function KidoSelectBehaviorFactory() {
    }
    KidoSelectBehaviorFactory.createSelectBehavior = function (config) {
        if (config.type == 'simple_selection') {
            return new SimpleSelectBehavior(config.layer);
        }
        else if (config.type == 'multiple_selection') {
            return new MultipleSelectBehavior(config.layer);
        }
        else if (config.type == 'origin_destination') {
            return new OriginDestinationSelectBehavior(config.layer);
        }
        else {
            return new NullSelectBehavior(config.layer);
        }
    };
    return KidoSelectBehaviorFactory;
}());
export { KidoSelectBehaviorFactory };
