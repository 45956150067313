import { KidoMap } from "./KidoMap";
import { MapViewState } from "./views/view";
import { MapViewStateManager } from "./views/viewmanager";
import { KidoLayerFactory } from "./layers/layers";
import { KidoSelectBehaviorFactory } from "./layers/behaviors/select";
import { KidoHoverBehaviorFactory } from "./layers/behaviors/hover";
var KidoMapFactory = /** @class */ (function () {
    function KidoMapFactory() {
    }
    KidoMapFactory.createKidoMap = function (environment, appConfig) {
        var activeProject = appConfig.projects[0];
        var baseMap = new KidoMap(activeProject.map);
        baseMap.setAppEnvironment(environment);
        var viewStateManager = KidoMapFactory.createViewStatesManager(baseMap, activeProject);
        baseMap.setViewStateManager(viewStateManager);
        var enabledHoverBehaviors = activeProject.map.enable_hover_behaviors || false;
        baseMap.setEnabledHoverBehaviors(enabledHoverBehaviors);
        return baseMap;
    };
    KidoMapFactory.createViewStatesManager = function (map, project) {
        var activeModule = project.modules.od;
        var stateViewsList = [];
        var rootStateView = null;
        activeModule.views.forEach(function (view_config) {
            var currentViewLayers = [];
            var boundaryLayer = null;
            view_config.layers.forEach(function (layer_config) {
                var kidoLayer = KidoLayerFactory.createKidoLayer(layer_config);
                kidoLayer.setMap(map);
                kidoLayer._geometryIDReferenceProperty = layer_config.geom_reference_property;
                kidoLayer._geometryLabelReferenceProperty = layer_config.label_reference_property;
                KidoSelectBehaviorFactory.createSelectBehavior({
                    'type': layer_config.select_behavior,
                    'layer': kidoLayer
                });
                KidoHoverBehaviorFactory.createHoverBehavior({
                    'type': layer_config.hover_behavior,
                    'layer': kidoLayer
                });
                if (layer_config.is_boundary_layer) {
                    boundaryLayer = kidoLayer;
                }
                currentViewLayers.push(kidoLayer);
            });
            var mapViewState = new MapViewState(view_config.name, view_config.visible, view_config.geometry_label, currentViewLayers);
            mapViewState.setBoundaryReferenceLayer(boundaryLayer);
            if (view_config.root_view) {
                rootStateView = mapViewState;
            }
            stateViewsList.push(mapViewState);
        });
        var viewManager = new MapViewStateManager(map, stateViewsList, rootStateView);
        return viewManager;
    };
    return KidoMapFactory;
}());
export { KidoMapFactory };
