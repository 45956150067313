import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEs from "./translations/es";
import translationEn from "./translations/en";

const resources = {
  en: {
    translation: translationEn,
  },
  es: {
    translation: translationEs,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: [
        "navigator",
        "querystring",
        "cookie",
        "localStorage",
        "htmlTag",
        "path",
        "subdomain",
      ],
    },
    resources,
    fallbackLng: "en",
    debug: true,
    keySeparator: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
