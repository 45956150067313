import { KidoEnvironment } from './KidoEnvironment';

export class EnvironmentImpl implements KidoEnvironment {
  production = false;
  app_urls = {
    //kido_api: "localhost:8888/api/tourismapp",
    kido_api: 'https://smartdestination.app/api/tourismapp',
    //kido_geoserver: 'http://localhost:8080/geoserver',
    kido_geoserver: 'https://smartdestination.app/geoserver/'
  };
  app_keys = {
    api_key: '2RlxfwkZ9h6TCVaFgpCbG24a3YNGuh1D4wsGJmrK'
  };
}
