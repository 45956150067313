"use strict";
module.exports = {
    light: {
        type: 'light',
        background: '#23232E',
        primaryColor: '#822ed8',
        fontColor: 'rgb(0,0,0, 0.5)',
        borderColor: 'rgba(0,0,0, 0.2)',
        dateFormat: 'YYYY-MM-DD',
        colors: [
            '#B12AB8',
            '#FFA4FF',
            '#007BF8',
            '#BC8FBC',
            '#6B77A9',
            '#008EED',
            '#6395F9',
            '#009E9B',
            '#0DD9A4',
            '#6B77A9',
            '#8E5EA2',
            '#FFE7FF',
            '#AA85A9',
            '#E8C3B9',
            '#3E95CD',
            '#5ABAB5',
            '#0DD9A4',
            '#056950',
            '#FB7716',
            '#B6A6B5',
            '#8E5EA2',
            '#E8C3B9'
        ]
    },
    dark: {
        type: 'dark',
        background: '#23232E',
        primaryColor: '#822ed8',
        fontColor: 'rgb(255,255,255, 0.5)',
        borderColor: 'rgba(0,0,0, 0.2)',
        dateFormat: 'DD-MM-YYYY',
        colors: [
            '#6395F9',
            '#0DD9A4',
            '#056950',
            '#8E5EA2',
            '#E8C3B9',
            '#3E95CD',
            '#0DD9A4',
            '#056950',
            '#8E5EA2',
            '#E8C3B9',
            '#BBDEDE',
            '#F8D0CB',
            '#FF9EC6'
        ]
    }
};
