export default {
  origin: "Origen",
  destination: "Destinación",
  date: "Fechas",
  microtrips: "Microtrips",
  "Select a origin": "Seleccione un origen",
  "Select a destination": "Seleccione una destinación",
  orDestination: "o una destinación",
  "Total trips": "Desplazamientos totales",
  "In/Out trips": "Desplazamientos In/Out",
  "In/In trips": "Desplazamientos In/In",
  moreStats: "Para más estadísticas visite",
  spain: "España",
  username: "Usuario",
  password: "Contraseña",
  login: "Acceso",
  cancel: "Cancelar",
  ok: "Acceder",
  help:
    "Click izquierdo para seleccionar origen, click derecho para seleccionar destinación",
  "reset origin": "Borrar origen",
  "reset destination": "Borrar destinación",
  flip: "Intercambiar origen-destinación",
  accept: "Aceptar",
  "info btn": "Notas técnicas y metodología",
};
